var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "venueForm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.validateForm("venueForm")
            },
          },
          model: {
            value: _vm.isFormValid,
            callback: function ($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid",
          },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "justify-space-between",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "12" } },
                [
                  _c("v-icon", [_vm._v(" mdi-food-outline ")]),
                  _c("h3", { staticClass: "form-title ml-4" }, [
                    _vm._v("General Info"),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex justify-md-end justify-sm-start align-center mt-sm-4",
                  attrs: { lg: "12", md: "12", sm: "12" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column gap-2",
                          attrs: { cols: "4" },
                        },
                        [
                          _vm.shouldPopulateForm
                            ? _c("Button", {
                                staticClass:
                                  "standart-button-content-width mb-1",
                                attrs: {
                                  text: "Change status",
                                  type: "button",
                                },
                                on: { handleClick: _vm.openVenueModal },
                              })
                            : _vm._e(),
                          _vm.shouldPopulateForm
                            ? _c("Button", {
                                staticClass:
                                  "standart-button-content-width mb-1",
                                attrs: {
                                  text: "Check provider",
                                  type: "button",
                                },
                                on: { handleClick: _vm.openInstanceCheckModal },
                              })
                            : _vm._e(),
                          _vm.shouldPopulateForm
                            ? _c("Button", {
                                staticClass:
                                  "standart-button-content-width mb-1",
                                attrs: {
                                  text: "Check Rkeeper",
                                  type: "button",
                                  isLoading: _vm.isCheckingRk,
                                },
                                on: { handleClick: _vm.checkRk },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "4" },
                        },
                        [
                          _vm.shouldPopulateForm
                            ? _c("Button", {
                                staticClass:
                                  "standart-button-content-width mb-1",
                                attrs: {
                                  type: "button",
                                  text: "Change schedule",
                                },
                                on: { handleClick: _vm.openVenueScheduleModal },
                              })
                            : _vm._e(),
                          _vm.getUserData.roleNames[0].toLowerCase() !==
                          "manager"
                            ? _c("Button", {
                                staticClass:
                                  "standart-button-content-width mb-1",
                                attrs: {
                                  text: "Change subgroup",
                                  type: "button",
                                },
                                on: { handleClick: _vm.openVenueGroupModal },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "4" },
                        },
                        [
                          _vm.shouldPopulateForm
                            ? _c("Button", {
                                staticClass:
                                  "standart-button-content-width mb-1",
                                attrs: {
                                  text: "Sync menu",
                                  type: "button",
                                  isLoading: _vm.isFormLoading,
                                },
                                on: { handleClick: _vm.openMenuModal },
                              })
                            : _vm._e(),
                          _c("Button", {
                            staticClass: "standart-button-content-width",
                            attrs: {
                              text: "Orders",
                              type: "button",
                              isLoading: _vm.isFormLoading,
                            },
                            on: {
                              handleClick: function ($event) {
                                return _vm.$router.push(
                                  `/new-orders-id/${_vm.venue.id}`
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-text-field", {
            staticClass:
              "standart-input-filled standart-input-no-message mt-4 mb-3",
            attrs: {
              label: "Name",
              disabled: !_vm.isFormEditable,
              rules: [_vm.rules.required],
              color: "dark_grey",
              filled: "",
              rounded: "",
              dense: "",
            },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between" },
            [
              _c("h3", { staticClass: "form-title mt-2 mb-6" }, [
                _vm._v("RKeeper settings"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "", color: "#5B7FFE" },
                  on: {
                    click: function ($event) {
                      _vm.isSettingsOpen.rKeeperSettings =
                        !_vm.isSettingsOpen.rKeeperSettings
                    },
                  },
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      _vm._s(
                        _vm.isSettingsOpen.rKeeperSettings
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      )
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSettingsOpen.rKeeperSettings,
                  expression: "isSettingsOpen.rKeeperSettings",
                },
              ],
              staticClass: "mb-4",
              attrs: { dense: "" },
            },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h5", [_vm._v("RKeeper HTTP interface settings")]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Ip",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("ip")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " External IP address of rkeeper HTTP interface "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.ip,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.settings.rKeeperSettings, "ip", $$v)
                      },
                      expression: "form.settings.rKeeperSettings.ip",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Login",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("login")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_vm._v(" Username for rkeeper HTTP interface ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.login,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "login",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.login",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Port",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("port")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " External port of rkeeper HTTP interface "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.port,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.settings.rKeeperSettings, "port", $$v)
                      },
                      expression: "form.settings.rKeeperSettings.port",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Pwd",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("pwd")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_vm._v(" Password for rkeeper HTTP interface ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.settings.rKeeperSettings, "pwd", $$v)
                      },
                      expression: "form.settings.rKeeperSettings.pwd",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h5", [_vm._v("Basic settings")]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Station Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("stationIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Station ID that where orders will be processed. "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.stationIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "stationIdent",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.stationIdent",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Waiter Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("waiterIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Waiter ID who will be used as creator of orders (will be used to determine price type) "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.waiterIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "waiterIdent",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.waiterIdent",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Void Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("voidIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_vm._v(" Void Ident ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.voidIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "voidIdent",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.voidIdent",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Order Type Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("orderTypeIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Order Type ID that will be used for creating orders "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.orderTypeIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "orderTypeIdent",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.orderTypeIdent",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Discount Id",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("discountId")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Discount ID that will be used for adding discounts to orders (Must have Changeable Value enabled) "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.discountId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "discountId",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.discountId",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Print Purpose Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("printPurposeIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Printer Purpose ID that will be used for printing order info slips "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value:
                        _vm.form.settings.rKeeperSettings.printPurposeIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "printPurposeIdent",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.printPurposeIdent",
                    },
                  }),
                  _c("v-autocomplete", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Default Delivery Method",
                      disabled: !_vm.isFormEditable,
                      rules: [_vm.rules.required],
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      items: _vm.deliveryMethods,
                      "item-text": _vm.getItemText,
                      "item-value": "id",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.selectLang($event, "defaultDeliveryMethod")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Default delivery method for all items "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value:
                        _vm.form.settings.rKeeperSettings.defaultDeliveryMethod,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "defaultDeliveryMethod",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.defaultDeliveryMethod",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Table Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("tableIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Table ID where orders will be created (will be used to determine price type) "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.tableIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "tableIdent",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.tableIdent",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Receipt Maket Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("receiptMaketIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Receipt document view ID used that will be used for receipts "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value:
                        _vm.form.settings.rKeeperSettings.receiptMaketIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "receiptMaketIdent",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.receiptMaketIdent",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Invoice Maket Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("invoiceMaketId")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Identifier of invoice view from print scheme "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.invoiceMaketId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "invoiceMaketId",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.invoiceMaketId",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Tax Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("taxIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " ID of Tax that will be used for menu generation "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.taxIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "taxIdent",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.taxIdent",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Combo Tax Rate",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("comboTaxRate")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Fixed Tax rate that will be used for combo items "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.comboTaxRate,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "comboTaxRate",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.comboTaxRate",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Pick Up Order Type Ident",
                      disabled: !_vm.isFormEditable,
                      rules: _vm.isRequiredField("pickUpOrderTypeIdent")
                        ? [_vm.rules.required]
                        : [],
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Order Type ID that will be used for creating pickup orders (if empty default Order Type ID will be used) "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value:
                        _vm.form.settings.rKeeperSettings.pickUpOrderTypeIdent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "pickUpOrderTypeIdent",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.pickUpOrderTypeIdent",
                    },
                  }),
                  _c("v-autocomplete", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6",
                    attrs: {
                      label: "Default Menu Language Id",
                      disabled: !_vm.isFormEditable,
                      rules: [_vm.rules.required],
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      items: _vm.languages,
                      "item-text": _vm.getItemText,
                      "item-value": "id",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.selectLang($event, "defaultMenuLanguageId")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v(" mdi-help-circle "),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [_vm._v(" Default menu language ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value:
                        _vm.form.settings.rKeeperSettings.defaultMenuLanguageId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "defaultMenuLanguageId",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.defaultMenuLanguageId",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h5", [_vm._v("Work Parameters")]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-checkbox", {
                    staticClass: "standart-checkbox",
                    attrs: { disabled: !_vm.isFormEditable },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(" isPayOrderEnabled "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g({ staticClass: "ml-2" }, on),
                                          [_vm._v(" mdi-help-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Enables PayOrder execution, receipt printing (if disabled order will stay in unpaid state) "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value:
                        _vm.form.settings.rKeeperSettings.isPayOrderEnabled,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "isPayOrderEnabled",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.isPayOrderEnabled",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "standart-checkbox",
                    attrs: { disabled: !_vm.isFormEditable },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(" isAutoPrintEnabled "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g({ staticClass: "ml-2" }, on),
                                          [_vm._v(" mdi-help-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Enables automatic order info slip printing once order is in production state "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value:
                        _vm.form.settings.rKeeperSettings.isAutoPrintEnabled,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "isAutoPrintEnabled",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.isAutoPrintEnabled",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "standart-checkbox",
                    attrs: { disabled: !_vm.isFormEditable },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(" isCreateInvoice "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g({ staticClass: "ml-2" }, on),
                                          [_vm._v(" mdi-help-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Enables/Disables printing of invoice in rkeeper "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.isCreateInvoice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "isCreateInvoice",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.isCreateInvoice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-checkbox", {
                    staticClass: "standart-checkbox",
                    attrs: { disabled: !_vm.isFormEditable },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(" isUseKds "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g({ staticClass: "ml-2" }, on),
                                          [_vm._v(" mdi-help-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Enables reading of order kdsstate and automatic order status change once marked as ready in KDS "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.isUseKds,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "isUseKds",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.isUseKds",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "standart-checkbox",
                    attrs: { disabled: !_vm.isFormEditable },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(" isDebugMode "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g({ staticClass: "ml-2" }, on),
                                          [_vm._v(" mdi-help-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " ONLY FOR DEBUG - When enabled orders WILL NOT BE CREATED, SAVED IN RKEEPER, only ValidateOrder command will be executed. "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.isDebugMode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "isDebugMode",
                          $$v
                        )
                      },
                      expression: "form.settings.rKeeperSettings.isDebugMode",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "standart-checkbox",
                    attrs: { disabled: !_vm.isFormEditable },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(" isUseComboName "),
                            _c(
                              "v-tooltip",
                              {
                                staticClass: "tooltip",
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g({ staticClass: "ml-2" }, on),
                                          [_vm._v(" mdi-help-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(
                                  " Enables usage of combo component names as they are defined in combo scheme instead of using their default menu item names "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.settings.rKeeperSettings.isUseComboName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.settings.rKeeperSettings,
                          "isUseComboName",
                          $$v
                        )
                      },
                      expression:
                        "form.settings.rKeeperSettings.isUseComboName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between" },
            [
              _c("h3", { staticClass: "form-title mt-2 mb-6" }, [
                _vm._v("Venue Self settings"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "", color: "#5B7FFE" },
                  on: {
                    click: function ($event) {
                      _vm.isSettingsOpen.venueSelfSettings =
                        !_vm.isSettingsOpen.venueSelfSettings
                    },
                  },
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      _vm._s(
                        _vm.isSettingsOpen.venueSelfSettings
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      )
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSettingsOpen.venueSelfSettings,
                  expression: "isSettingsOpen.venueSelfSettings",
                },
              ],
              staticClass: "mb-4",
            },
            [
              !_vm.isNew
                ? _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass:
                          "standart-input-filled standart-input-no-message mb-6",
                        attrs: {
                          label: "Venue Self Id",
                          readonly: !_vm.isNew,
                          rules: [_vm.rules.required],
                          type: "text",
                          color: "dark_grey",
                          "append-icon": _vm.isVenueSelfIdCopied
                            ? "mdi-check"
                            : "mdi-content-copy",
                          filled: "",
                          rounded: "",
                          dense: "",
                        },
                        on: {
                          "click:append": function ($event) {
                            return _vm.copyClipboard(
                              "isVenueSelfIdCopied",
                              _vm.form.id
                            )
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-outer",
                              fn: function () {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c("v-icon", _vm._g({}, on), [
                                                  _vm._v(" mdi-help-circle "),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2677512173
                                      ),
                                    },
                                    [_vm._v(" Venue self ID. ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          986380396
                        ),
                        model: {
                          value: _vm.form.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "id", $$v)
                          },
                          expression: "form.id",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isNew
                ? _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass:
                          "standart-input-filled standart-input-no-message mb-6",
                        attrs: {
                          value: _vm.formatDateTime(
                            _vm.dateTimeFormats,
                            _vm.form.menuUpdateTimeUtc
                          ),
                          label: "Last menu sync time",
                          readonly: !_vm.isNew,
                          rules: [_vm.rules.required],
                          type: "text",
                          color: "dark_grey",
                          filled: "",
                          rounded: "",
                          dense: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-outer",
                              fn: function () {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c("v-icon", _vm._g({}, on), [
                                                  _vm._v(" mdi-help-circle "),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2677512173
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        " Last time when menu was automatically synchronized between rkeeper and delivery platforms. "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3683147337
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(
                Object.keys(this.form.settings.venueSelfSettings).filter(
                  (el) =>
                    ![
                      "woltStartDateUtc",
                      "boltStartDateUtc",
                      "fudyStartDateUtc",
                      "woltExpiredDateUtc",
                      "boltExpiredDateUtc",
                      "fudyExpiredDateUtc",
                      "glovoExpiredDateUtc",
                    ].includes(el)
                ),
                function (key) {
                  return _c(
                    "v-col",
                    { key: key, staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _vm.fieldTypes[key] &&
                      _vm.fieldTypes[key].type === "checkbox"
                        ? _c("v-checkbox", {
                            staticClass: "standart-checkbox",
                            attrs: {
                              disabled:
                                key === "useDayLigthSavingTime"
                                  ? !_vm.form.settings.venueSelfSettings
                                      .useCustomTimeZone
                                  : !_vm.isFormEditable,
                              label: key,
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.venueSelfSettingsDesctiptions[key]
                                  ? {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(" " + _vm._s(key) + " "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " mdi-help-circle "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .venueSelfSettingsDesctiptions[
                                                      key
                                                    ]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.form.settings.venueSelfSettings[key],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.settings.venueSelfSettings,
                                  key,
                                  $$v
                                )
                              },
                              expression:
                                "form.settings.venueSelfSettings[key]",
                            },
                          })
                        : _vm.fieldTypes[key] &&
                          _vm.fieldTypes[key].type === "select"
                        ? _c(
                            "v-row",
                            [
                              key === "timeZone"
                                ? _c(
                                    "v-col",
                                    [
                                      _c("v-autocomplete", {
                                        staticClass:
                                          "standart-input-filled standart-input-no-message mb-6",
                                        attrs: {
                                          label: "Time zone",
                                          color: "dark_grey",
                                          items: _vm.timeZones,
                                          "item-text": "name",
                                          "item-value": "id",
                                          rules: [_vm.rules.required],
                                          disabled:
                                            !_vm.form.settings.venueSelfSettings
                                              .useCustomTimeZone,
                                          "loader-height": "10",
                                          filled: "",
                                          rounded: "",
                                          dense: "",
                                        },
                                        model: {
                                          value:
                                            _vm.form.settings.venueSelfSettings[
                                              key
                                            ],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.settings
                                                .venueSelfSettings,
                                              key,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.settings.venueSelfSettings[key]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass:
                                          "standart-input-filled standart-input-no-message mb-6",
                                        attrs: {
                                          label: key,
                                          disabled:
                                            !_vm.isFormEditable ||
                                            _vm.isSelfImageSourceId,
                                          color: "dark_grey",
                                          items: _vm.getIdNames,
                                          "item-text": "name",
                                          "item-value": "id",
                                          loading: _vm.idNamesLoading,
                                          "loader-height": "10",
                                          filled: "",
                                          rounded: "",
                                          dense: "",
                                        },
                                        on: {
                                          focus: _vm.getIdNamesFocused,
                                          input: _vm.selectImageSourceId,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append-outer",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-help-circle "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .venueSelfSettingsDesctiptions[
                                                              key
                                                            ]
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value:
                                            _vm.form.settings.venueSelfSettings[
                                              key
                                            ],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.settings
                                                .venueSelfSettings,
                                              key,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.settings.venueSelfSettings[key]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                              key !== "timeZone"
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "standart-checkbox",
                                        attrs: {
                                          disabled: !_vm.isFormEditable,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    ' Select this venue" '
                                                  ),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2",
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-help-circle "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        " If enabled will use its own venue if for image urls. "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.isSelfImageSourceId,
                                          callback: function ($$v) {
                                            _vm.isSelfImageSourceId = $$v
                                          },
                                          expression: "isSelfImageSourceId",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm.fieldTypes[key] &&
                          _vm.fieldTypes[key].type === "number"
                        ? _c("v-text-field", {
                            staticClass:
                              "standart-input-filled standart-input-no-message mb-6",
                            attrs: {
                              disabled: !_vm.isFormEditable,
                              label: key,
                              type: "number",
                              filled: "",
                              rounded: "",
                              dense: "",
                              min: "0",
                              max: "420",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g({}, on),
                                                      [
                                                        _vm._v(
                                                          " mdi-help-circle "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .venueSelfSettingsDesctiptions[
                                                  key
                                                ]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.form.settings.venueSelfSettings[key],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.settings.venueSelfSettings,
                                  key,
                                  $$v
                                )
                              },
                              expression:
                                "form.settings.venueSelfSettings[key]",
                            },
                          })
                        : _c("v-text-field", {
                            staticClass:
                              "standart-input-filled standart-input-no-message mb-6",
                            attrs: {
                              label: key,
                              disabled:
                                _vm.isDisabledForManager(key) ||
                                !_vm.isFormEditable,
                              rules: _vm.isRequiredField(key)
                                ? [_vm.rules.required]
                                : [],
                              type: "text",
                              color: "dark_grey",
                              filled: "",
                              rounded: "",
                              dense: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g({}, on),
                                                      [
                                                        _vm._v(
                                                          " mdi-help-circle "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .venueSelfSettingsDesctiptions[
                                                  key
                                                ]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.form.settings.venueSelfSettings[key],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.settings.venueSelfSettings,
                                  key,
                                  $$v
                                )
                              },
                              expression:
                                "form.settings.venueSelfSettings[key]",
                            },
                          }),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h4", { staticClass: "form-title d-flex align-center" }, [
                _vm._v("Wolt instances:"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "", color: "#000000" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddInstanceModal("woltSettings")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-plus-circle-outline")])],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.form.woltSettings, function (instance, index) {
            return _c(
              "div",
              { key: `woltSettings-${instance.id ?? index}` },
              [
                _c(
                  "div",
                  { staticClass: "my-2 d-flex justify-space-between" },
                  [
                    _c(
                      "h4",
                      { staticClass: "form-title d-flex align-center" },
                      [
                        _vm._v(
                          " Wolt settings #" +
                            _vm._s(index + 1) +
                            " " +
                            _vm._s(_vm.form.woltSettings[index].displayName) +
                            " " +
                            _vm._s(
                              _vm.form.woltSettings[index].id
                                ? `- ID ${_vm.form.woltSettings[index].id}`
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", large: "", color: "#5B7FFE" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleInstanceView(
                                  "woltOpen",
                                  instance.id
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", [
                              _vm._v(
                                _vm._s(
                                  _vm.woltOpen.includes(instance.id)
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.woltOpen.includes(instance.id)
                  ? _c(
                      "v-row",
                      { staticClass: "mb-8" },
                      [
                        _vm._l(
                          Object.keys(instance).filter(
                            (k) => k != "id" && k != "isEnabled"
                          ),
                          function (key) {
                            return _c(
                              "v-col",
                              {
                                key: key,
                                staticClass: "py-0",
                                attrs: { cols: "6" },
                              },
                              [
                                key == "url"
                                  ? _c("v-autocomplete", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        items: _vm.woltSettingsUrl,
                                        color: "dark_grey",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      model: {
                                        value:
                                          _vm.form.woltSettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.woltSettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.woltSettings[index][key]",
                                      },
                                    })
                                  : key == "venueId"
                                  ? _c("v-text-field", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        disabled: !_vm.isFormEditable,
                                        rules: !instance.isEnabled
                                          ? [_vm.rules.required]
                                          : [],
                                        type: "text",
                                        color: "dark_grey",
                                        "append-icon": _vm.isWoltVenueIdCopied
                                          ? "mdi-check"
                                          : "mdi-content-copy",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          return _vm.copyClipboard(
                                            "isWoltVenueIdCopied",
                                            _vm.form.woltSettings[index][key]
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.form.woltSettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.woltSettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.woltSettings[index][key]",
                                      },
                                    })
                                  : _c("v-text-field", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        disabled: !_vm.isFormEditable,
                                        rules:
                                          !instance.isEnabled &&
                                          _vm.isRequiredField(key)
                                            ? [_vm.rules.required]
                                            : [],
                                        type: "text",
                                        color: "dark_grey",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      model: {
                                        value:
                                          _vm.form.woltSettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.woltSettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.woltSettings[index][key]",
                                      },
                                    }),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "py-3 d-flex align-center justify-space-between",
                            attrs: { cols: "12" },
                          },
                          [
                            _c("v-switch", {
                              staticClass: "standart-switch ma-0 ml-5",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: _vm.form.woltSettings[index].isEnabled
                                  ? "Enabled"
                                  : "Disabled",
                              },
                              model: {
                                value: _vm.form.woltSettings[index].isEnabled,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.woltSettings[index],
                                    "isEnabled",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.woltSettings[index].isEnabled",
                              },
                            }),
                            _c("Button", {
                              staticClass: "standart-button-content-width",
                              attrs: {
                                styleType: "primary",
                                text: "Delete",
                                type: "button",
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.openDeleteInstanceModal(
                                    "woltSettings",
                                    instance
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h4", { staticClass: "form-title d-flex align-center" }, [
                _vm._v("Fudy instances:"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "", color: "#000000" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddInstanceModal("fudySettings")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-plus-circle-outline")])],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.form.fudySettings, function (instance, index) {
            return _c(
              "div",
              { key: `fudySettings-${instance.id ?? index}` },
              [
                _c(
                  "div",
                  { staticClass: "my-2 d-flex justify-space-between" },
                  [
                    _c(
                      "h3",
                      { staticClass: "form-title d-flex align-center" },
                      [
                        _vm._v(
                          " Fudy settings #" +
                            _vm._s(index + 1) +
                            " " +
                            _vm._s(_vm.form.fudySettings[index].displayName) +
                            " " +
                            _vm._s(
                              _vm.form.fudySettings[index].id
                                ? `- ID ${_vm.form.fudySettings[index].id}`
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", large: "", color: "#5B7FFE" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleInstanceView(
                              "fudyOpen",
                              instance.id
                            )
                          },
                        },
                      },
                      [
                        _c("v-icon", [
                          _vm._v(
                            _vm._s(
                              _vm.fudyOpen.includes(instance.id)
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.fudyOpen.includes(instance.id)
                  ? _c(
                      "v-row",
                      { staticClass: "mb-8" },
                      [
                        _vm._l(
                          Object.keys(instance).filter(
                            (k) => k != "id" && k != "isEnabled"
                          ),
                          function (key) {
                            return _c(
                              "v-col",
                              {
                                key: key,
                                staticClass: "py-0",
                                attrs: { cols: "6" },
                              },
                              [
                                key == "url"
                                  ? _c("v-autocomplete", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        items: _vm.fudySettingsUrl,
                                        color: "dark_grey",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      model: {
                                        value:
                                          _vm.form.fudySettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.fudySettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.fudySettings[index][key]",
                                      },
                                    })
                                  : key == "venueId"
                                  ? _c("v-text-field", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        disabled: !_vm.isFormEditable,
                                        rules: !instance.isEnabled
                                          ? [_vm.rules.required]
                                          : [],
                                        type: "text",
                                        color: "dark_grey",
                                        "append-icon": _vm.isFudyVenueIdCopied
                                          ? "mdi-check"
                                          : "mdi-content-copy",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          return _vm.copyClipboard(
                                            "isFudyVenueIdCopied",
                                            _vm.form.fudySettings[index][key]
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.form.fudySettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.fudySettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.fudySettings[index][key]",
                                      },
                                    })
                                  : _c("v-text-field", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        disabled: !_vm.isFormEditable,
                                        rules:
                                          !instance.isEnabled &&
                                          _vm.isRequiredField(key)
                                            ? [_vm.rules.required]
                                            : [],
                                        type: "text",
                                        color: "dark_grey",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      model: {
                                        value:
                                          _vm.form.fudySettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.fudySettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.fudySettings[index][key]",
                                      },
                                    }),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "py-3 d-flex align-center justify-space-between",
                            attrs: { cols: "12" },
                          },
                          [
                            _c("v-switch", {
                              staticClass: "standart-switch ma-0 ml-5",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: _vm.form.fudySettings[index].isEnabled
                                  ? "Enabled"
                                  : "Disabled",
                              },
                              model: {
                                value: _vm.form.fudySettings[index].isEnabled,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.fudySettings[index],
                                    "isEnabled",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.fudySettings[index].isEnabled",
                              },
                            }),
                            _c("Button", {
                              staticClass: "standart-button-content-width",
                              attrs: {
                                styleType: "primary",
                                text: "Delete",
                                type: "button",
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.openDeleteInstanceModal(
                                    "fudySettings",
                                    instance
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h4", { staticClass: "form-title d-flex align-center" }, [
                _vm._v("Bolt instances:"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "", color: "#000000" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddInstanceModal("boltSettings")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-plus-circle-outline")])],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.form.boltSettings, function (instance, index) {
            return _c(
              "div",
              { key: `boltSettings-${instance.id ?? index}` },
              [
                _c(
                  "div",
                  { staticClass: "my-2 d-flex justify-space-between" },
                  [
                    _c(
                      "h3",
                      { staticClass: "form-title d-flex align-center" },
                      [
                        _vm._v(
                          " Bolt settings #" +
                            _vm._s(index + 1) +
                            " " +
                            _vm._s(_vm.form.boltSettings[index].displayName) +
                            " " +
                            _vm._s(
                              _vm.form.boltSettings[index].id
                                ? `- ID ${_vm.form.boltSettings[index].id}`
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", large: "", color: "#5B7FFE" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleInstanceView(
                              "boltOpen",
                              instance.id
                            )
                          },
                        },
                      },
                      [
                        _c("v-icon", [
                          _vm._v(
                            _vm._s(
                              _vm.boltOpen.includes(instance.id)
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.boltOpen.includes(instance.id)
                  ? _c(
                      "v-row",
                      [
                        _vm._l(
                          Object.keys(instance).filter(
                            (k) =>
                              k != "id" &&
                              k != "isEnabled" &&
                              k != "tableIds" &&
                              k != "isEnabledTableMapping" &&
                              k != "isPayAndGoSettings" &&
                              k != "paymentType" &&
                              k != "tipsItemId"
                          ),
                          function (key) {
                            return _c(
                              "v-col",
                              {
                                key: key,
                                staticClass: "py-0",
                                attrs: { cols: "6" },
                              },
                              [
                                key == "url"
                                  ? _c("v-autocomplete", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        items: _vm.boltSettingsUrl,
                                        color: "dark_grey",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      model: {
                                        value:
                                          _vm.form.boltSettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.boltSettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.boltSettings[index][key]",
                                      },
                                    })
                                  : _c("v-text-field", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        disabled: !_vm.isFormEditable,
                                        rules:
                                          !instance.isEnabled &&
                                          _vm.isRequiredField(key)
                                            ? [_vm.rules.required]
                                            : [],
                                        type: "text",
                                        color: "dark_grey",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      model: {
                                        value:
                                          _vm.form.boltSettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.boltSettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.boltSettings[index][key]",
                                      },
                                    }),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 d-flex",
                                attrs: { cols: "6" },
                              },
                              [
                                _c("h5", [_vm._v("Pay and Go settings")]),
                                _c("v-switch", {
                                  staticClass: "standart-switch ma-0 ml-5",
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    label: _vm.form.boltSettings[index]
                                      .isPayAndGoSettings
                                      ? "Enabled"
                                      : "Disabled",
                                  },
                                  model: {
                                    value:
                                      _vm.form.boltSettings[index]
                                        .isPayAndGoSettings,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.boltSettings[index],
                                        "isPayAndGoSettings",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.boltSettings[index].isPayAndGoSettings",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.form.boltSettings[index].isPayAndGoSettings
                              ? _c(
                                  "div",
                                  { staticClass: "pay-and-go" },
                                  [
                                    _c("Button", {
                                      staticClass: "edit-table-mapping-button",
                                      attrs: {
                                        styleType: "primary",
                                        text: "Edit Table Mapping",
                                        type: "button",
                                      },
                                      on: {
                                        handleClick: function ($event) {
                                          return _vm.openTableMappingModal(
                                            index
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 d-flex",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          staticClass:
                                            "standart-input-filled standart-input-no-message mb-6",
                                          staticStyle: { height: "100%" },
                                          attrs: {
                                            label: "paymentType",
                                            items: _vm.paymentTypes,
                                            color: "dark_grey",
                                            filled: "",
                                            rounded: "",
                                            dense: "",
                                          },
                                          model: {
                                            value:
                                              _vm.form.boltSettings[index]
                                                .paymentType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.boltSettings[index],
                                                "paymentType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.boltSettings[index].paymentType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.boltSettings[index].isPayAndGoSettings
                              ? _c(
                                  "div",
                                  { staticClass: "pay-and-go" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 d-flex",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "standart-input-filled standart-input-no-message mb-6",
                                          attrs: {
                                            label: "tipsItemId",
                                            disabled: !_vm.isFormEditable,
                                            rules:
                                              !instance.isEnabled &&
                                              _vm.isRequiredField("tipsItemId")
                                                ? [_vm.rules.required]
                                                : [],
                                            type: "text",
                                            color: "dark_grey",
                                            filled: "",
                                            rounded: "",
                                            dense: "",
                                          },
                                          model: {
                                            value:
                                              _vm.form.boltSettings[index]
                                                .tipsItemId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.boltSettings[index],
                                                "tipsItemId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.boltSettings[index].tipsItemId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "1200px" },
                            model: {
                              value: _vm.isTableMappingModalOpen,
                              callback: function ($$v) {
                                _vm.isTableMappingModalOpen = $$v
                              },
                              expression: "isTableMappingModalOpen",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "headline" },
                                  [_vm._v("Table Mapping")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "overflow-y": "auto",
                                      "max-height": "400px",
                                      height: "400px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _c("v-simple-table", {
                                          attrs: { dense: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function () {
                                                  return [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c("th", [
                                                          _vm._v(
                                                            "Bolt Table Id"
                                                          ),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v(
                                                            "RKeeper Table Id"
                                                          ),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Actions"),
                                                        ]),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        _vm.form.boltSettings[
                                                          index
                                                        ].tableIds,
                                                        function (
                                                          table,
                                                          tableIndex
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            { key: tableIndex },
                                                            [
                                                              _c("td", [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        table.boltTableId,
                                                                      expression:
                                                                        "table.boltTableId",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "compact-input",
                                                                  attrs: {
                                                                    type: "text",
                                                                    placeholder:
                                                                      "Enter Bolt Table Id",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      table.boltTableId,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          table,
                                                                          "boltTableId",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]),
                                                              _c("td", [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        table.rKeeperTableId,
                                                                      expression:
                                                                        "table.rKeeperTableId",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "compact-input",
                                                                  attrs: {
                                                                    type: "text",
                                                                    placeholder:
                                                                      "Enter RKeeper Table Id",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      table.rKeeperTableId,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          table,
                                                                          "rKeeperTableId",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]),
                                                              _c("td", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "mini-button delete-button",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteTableMapping(
                                                                            index,
                                                                            tableIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Delete "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mt-4",
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "mini-button add-button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addTableMapping()
                                                  },
                                                },
                                              },
                                              [_vm._v("Add Mapping")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "mini-button close-button",
                                        on: {
                                          click: () =>
                                            (_vm.isTableMappingModalOpen = false),
                                        },
                                      },
                                      [_vm._v("Close")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "py-3 d-flex align-center justify-space-between",
                            attrs: { cols: "12" },
                          },
                          [
                            _c("v-switch", {
                              staticClass: "standart-switch ma-0 ml-5",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: _vm.form.boltSettings[index].isEnabled
                                  ? "Enabled"
                                  : "Disabled",
                              },
                              model: {
                                value: _vm.form.boltSettings[index].isEnabled,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.boltSettings[index],
                                    "isEnabled",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.boltSettings[index].isEnabled",
                              },
                            }),
                            _c("Button", {
                              staticClass: "standart-button-content-width",
                              attrs: {
                                styleType: "primary",
                                text: "Delete",
                                type: "button",
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.openDeleteInstanceModal(
                                    "boltSettings",
                                    instance
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h4", { staticClass: "form-title d-flex align-center" }, [
                _vm._v("Glovo instances:"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "", color: "#000000" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddInstanceModal("glovoSettings")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-plus-circle-outline")])],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.form.glovoSettings, function (instance, index) {
            return _c(
              "div",
              { key: `glovoSettings-${instance.id ?? index}` },
              [
                _c(
                  "div",
                  { staticClass: "my-2 d-flex justify-space-between" },
                  [
                    _c(
                      "h3",
                      { staticClass: "form-title d-flex align-center" },
                      [
                        _vm._v(
                          " Glovo settings #" +
                            _vm._s(index + 1) +
                            " " +
                            _vm._s(_vm.form.glovoSettings[index].displayName) +
                            " " +
                            _vm._s(
                              _vm.form.glovoSettings[index].id
                                ? `- ID ${_vm.form.glovoSettings[index].id}`
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", large: "", color: "#5B7FFE" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleInstanceView(
                              "glovoOpen",
                              instance.id
                            )
                          },
                        },
                      },
                      [
                        _c("v-icon", [
                          _vm._v(
                            _vm._s(
                              _vm.glovoOpen.includes(instance.id)
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.glovoOpen.includes(instance.id)
                  ? _c(
                      "v-row",
                      [
                        _vm._l(
                          Object.keys(instance).filter(
                            (k) => k != "id" && k != "isEnabled"
                          ),
                          function (key) {
                            return _c(
                              "v-col",
                              {
                                key: key,
                                staticClass: "py-0",
                                attrs: { cols: "6" },
                              },
                              [
                                key == "url"
                                  ? _c("v-autocomplete", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        items: _vm.glovoSettingsUrl,
                                        color: "dark_grey",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      model: {
                                        value:
                                          _vm.form.glovoSettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.glovoSettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.glovoSettings[index][key]",
                                      },
                                    })
                                  : _c("v-text-field", {
                                      staticClass:
                                        "standart-input-filled standart-input-no-message mb-6",
                                      attrs: {
                                        label: key,
                                        disabled: !_vm.isFormEditable,
                                        rules:
                                          !instance.isEnabled &&
                                          _vm.isRequiredField(key)
                                            ? [_vm.rules.required]
                                            : [],
                                        type: "text",
                                        color: "dark_grey",
                                        filled: "",
                                        rounded: "",
                                        dense: "",
                                      },
                                      model: {
                                        value:
                                          _vm.form.glovoSettings[index][key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.glovoSettings[index],
                                            key,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.glovoSettings[index][key]",
                                      },
                                    }),
                              ],
                              1
                            )
                          }
                        ),
                        _vm._l(
                          _vm.form.glovoSettings[index].tableIds,
                          function (table, tableIndex) {
                            return _c(
                              "v-col",
                              { key: tableIndex, attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "5" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "standart-input-filled standart-input-no-message mb-6",
                                          attrs: {
                                            label: "Glovo Table Id",
                                            disabled: !_vm.isFormEditable,
                                            rules:
                                              !instance.isEnabled &&
                                              _vm.isRequiredField("tableIds")
                                                ? [_vm.rules.required]
                                                : [],
                                            type: "text",
                                            color: "dark_grey",
                                            filled: "",
                                            rounded: "",
                                            dense: "",
                                          },
                                          model: {
                                            value:
                                              _vm.form.glovoSettings[index]
                                                .tableIds[tableIndex]
                                                .glovoTableId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.glovoSettings[index]
                                                  .tableIds[tableIndex],
                                                "glovoTableId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.glovoSettings[index].tableIds[tableIndex].glovoTableId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "5" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "standart-input-filled standart-input-no-message mb-6",
                                          attrs: {
                                            label: "RKeeper Table Id",
                                            disabled: !_vm.isFormEditable,
                                            rules:
                                              !instance.isEnabled &&
                                              _vm.isRequiredField(
                                                "rKeeperTableId"
                                              )
                                                ? [_vm.rules.required]
                                                : [],
                                            type: "text",
                                            color: "dark_grey",
                                            filled: "",
                                            rounded: "",
                                            dense: "",
                                          },
                                          model: {
                                            value:
                                              _vm.form.glovoSettings[index]
                                                .tableIds[tableIndex]
                                                .rKeeperTableId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.glovoSettings[index]
                                                  .tableIds[tableIndex],
                                                "rKeeperTableId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.glovoSettings[index].tableIds[tableIndex].rKeeperTableId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "2" } },
                                      [
                                        _c("Button", {
                                          staticClass:
                                            "standart-button-content-width",
                                          attrs: {
                                            styleType: "primary",
                                            text: "Delete",
                                            type: "button",
                                          },
                                          on: {
                                            handleClick: function ($event) {
                                              return _vm.deleteTableMapping(
                                                index,
                                                tableIndex
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "py-3 d-flex align-center justify-space-between",
                            attrs: { cols: "12" },
                          },
                          [
                            _c("v-switch", {
                              staticClass: "standart-switch ma-0 ml-5",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: _vm.form.glovoSettings[index].isEnabled
                                  ? "Enabled"
                                  : "Disabled",
                              },
                              model: {
                                value: _vm.form.glovoSettings[index].isEnabled,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.glovoSettings[index],
                                    "isEnabled",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.glovoSettings[index].isEnabled",
                              },
                            }),
                            _c("Button", {
                              staticClass: "standart-button-content-width",
                              attrs: {
                                styleType: "primary",
                                text: "Delete",
                                type: "button",
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.openDeleteInstanceModal(
                                    "glovoSettings",
                                    instance
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between" },
            [
              _c(
                "h3",
                { staticClass: "form-title mt-2 mb-6 d-flex align-center" },
                [_vm._v("Licenses")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "", color: "#5B7FFE" },
                  on: {
                    click: function ($event) {
                      _vm.isSettingsOpen.licenses = !_vm.isSettingsOpen.licenses
                    },
                  },
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      _vm._s(
                        _vm.isSettingsOpen.licenses
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      )
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isSettingsOpen.licenses
            ? [
                _c(
                  "v-row",
                  { staticClass: "mb-8" },
                  [
                    _vm._l(
                      [
                        "boltExpiredDateUtc",
                        "woltExpiredDateUtc",
                        "fudyExpiredDateUtc",
                        "glovoExpiredDateUtc",
                      ],
                      function (expiredDate) {
                        return _c(
                          "v-col",
                          { key: expiredDate, attrs: { cols: "6" } },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "d-flex align-bottom",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "8" } },
                                  [
                                    _c("v-datetime-picker", {
                                      attrs: {
                                        disabled: _vm.isLicenseEditDisabled,
                                        textFieldProps:
                                          _vm.dateTimeTextFieldProps,
                                        label: expiredDate,
                                        dateFormat: _vm.dateFormat,
                                        timeFormat: _vm.timeFormat,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.dateTimeChanged(
                                            expiredDate
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "actions",
                                            fn: function ({ parent }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "error lighten-1",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return parent.clearHandler.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Clear")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "success darken-1",
                                                    },
                                                    on: {
                                                      click: parent.okHandler,
                                                    },
                                                  },
                                                  [_vm._v("Ok")]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "dateIcon",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "blue darken-2",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-calendar-range "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "timeIcon",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "blue darken-2",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-clock-outline "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value:
                                          _vm.form.settings.venueSelfSettings[
                                            expiredDate
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.settings.venueSelfSettings,
                                            expiredDate,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.settings.venueSelfSettings[expiredDate]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-col", { attrs: { cols: "4" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "datetime-unlim-checkbox d-flex justify-center align-center",
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "standart-checkbox",
                                        attrs: {
                                          disabled:
                                            !_vm.isFormEditable ||
                                            _vm.isLicenseEditDisabled,
                                          label: "Unlimited",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.unlimCheckboxChange(
                                              $event,
                                              expiredDate
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.expDateUnlimModel[expiredDate],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.expDateUnlimModel,
                                              expiredDate,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "expDateUnlimModel[expiredDate]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    _vm.isRequestLicenseAllowed
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("Button", {
                              staticClass: "standart-button-content-width",
                              attrs: {
                                text: "Request license",
                                styleType: "primary",
                                type: "button",
                              },
                              on: { handleClick: _vm.requestLicenseHandler },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between mt-4" },
            [
              _c("Button", {
                staticClass: "standart-button-content-width",
                attrs: {
                  text: "Cancel",
                  styleType: "secondary",
                  type: "button",
                },
                on: { handleClick: _vm.resetForm },
              }),
              _c("Button", {
                staticClass: "standart-button-content-width",
                attrs: {
                  text: "Save",
                  styleType: "primary",
                  type: "submit",
                  isLoading: _vm.isFormLoading,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("VenueModal", {
        attrs: {
          "change-status": _vm.changeVenueStatus,
          availablities: _vm.availablities,
          isLoading: _vm.isFormLoading,
        },
        model: {
          value: _vm.isVenueModalShow,
          callback: function ($$v) {
            _vm.isVenueModalShow = $$v
          },
          expression: "isVenueModalShow",
        },
      }),
      _c("MenuModal", {
        attrs: { isLoading: _vm.isFormLoading, venueId: _vm.venue.id },
        model: {
          value: _vm.isMenuModalShow,
          callback: function ($$v) {
            _vm.isMenuModalShow = $$v
          },
          expression: "isMenuModalShow",
        },
      }),
      _c("VenueScheduleModal", {
        attrs: {
          "change-schedule": _vm.changeVenueSchedule,
          "is-schedule-loading": _vm.isScheduleLoading,
          schedule: _vm.schedule.availability,
        },
        model: {
          value: _vm.isScheduleModalShow,
          callback: function ($$v) {
            _vm.isScheduleModalShow = $$v
          },
          expression: "isScheduleModalShow",
        },
      }),
      _c("SelectGroupModal", {
        on: { saveGroupId: _vm.changeGroupId },
        model: {
          value: _vm.selectionGroupModalShown,
          callback: function ($$v) {
            _vm.selectionGroupModalShown = $$v
          },
          expression: "selectionGroupModalShown",
        },
      }),
      _c("RequestLicenseModal", {
        on: { sendReqLicForm: _vm.sendReqLicHandler },
        model: {
          value: _vm.reqLicenseModalShown,
          callback: function ($$v) {
            _vm.reqLicenseModalShown = $$v
          },
          expression: "reqLicenseModalShown",
        },
      }),
      _c("ChangeVenueGroupModal", {
        attrs: { formData: _vm.venue, venueGroups: _vm.venueGroupsData },
        on: { saveForm: _vm.saveEdit },
        model: {
          value: _vm.isVenueGroupModalShow,
          callback: function ($$v) {
            _vm.isVenueGroupModalShow = $$v
          },
          expression: "isVenueGroupModalShow",
        },
      }),
      _c("CheckInstancesModal", {
        attrs: {
          availablities: _vm.availablities,
          shouldPopulateForm: _vm.shouldPopulateForm,
          isCheckingFudy: _vm.isCheckingFudy,
          isCheckingBolt: _vm.isCheckingBolt,
          isCheckingWolt: _vm.isCheckingWolt,
        },
        on: {
          checkWolt: _vm.checkWolt,
          checkBolt: _vm.checkBolt,
          checkFudy: _vm.checkFudy,
        },
        model: {
          value: _vm.isCheckInstancesModalShow,
          callback: function ($$v) {
            _vm.isCheckInstancesModalShow = $$v
          },
          expression: "isCheckInstancesModalShow",
        },
      }),
      _c("DeleteInstanceModal", {
        attrs: { instanceToDelete: _vm.instanceToDelete },
        on: { deleteInstance: _vm.deleteInstance },
        model: {
          value: _vm.deleteInstanceModalShow,
          callback: function ($$v) {
            _vm.deleteInstanceModalShow = $$v
          },
          expression: "deleteInstanceModalShow",
        },
      }),
      _c("AddInstanceModal", {
        attrs: { instanceToAdd: _vm.instanceToAdd },
        on: { addInstance: _vm.addInstance },
        model: {
          value: _vm.addInstanceModalShow,
          callback: function ($$v) {
            _vm.addInstanceModalShow = $$v
          },
          expression: "addInstanceModalShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }