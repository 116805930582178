<template>
  <div>
    <v-form ref="venueForm" v-model="isFormValid" @submit.prevent="validateForm('venueForm')">
      <v-row no-gutters class="justify-space-between">
        <v-col cols="12" class="d-flex align-center">
          <v-icon> mdi-food-outline </v-icon>
          <h3 class="form-title ml-4">General Info</h3>
        </v-col>
        <v-col lg="12" md="12" sm="12" class="d-flex justify-md-end justify-sm-start align-center mt-sm-4">
          <v-row>
            <v-col class="d-flex flex-column gap-2" cols="4">
              <Button v-if="shouldPopulateForm" text="Change status" class="standart-button-content-width mb-1"
                type="button" @handleClick="openVenueModal" />
              <Button v-if="shouldPopulateForm" text="Check provider" class="standart-button-content-width mb-1"
                type="button" @handleClick="openInstanceCheckModal" />
              <Button v-if="shouldPopulateForm" text="Check Rkeeper" class="standart-button-content-width mb-1"
                type="button" :isLoading="isCheckingRk" @handleClick="checkRk" />
            </v-col>
            <v-col class="d-flex flex-column" cols="4">
              <Button v-if="shouldPopulateForm" type="button" text="Change schedule"
                class="standart-button-content-width mb-1" @handleClick="openVenueScheduleModal">
              </Button>
              <Button v-if="getUserData.roleNames[0].toLowerCase() !== 'manager'" text="Change subgroup"
                class="standart-button-content-width mb-1" type="button" @handleClick="openVenueGroupModal" />
            </v-col>
            <v-col class="d-flex flex-column" cols="4">
              <Button v-if="shouldPopulateForm" text="Sync menu" class="standart-button-content-width mb-1"
                type="button" :isLoading="isFormLoading" @handleClick="openMenuModal" />
              <Button text="Orders" class="standart-button-content-width" type="button" :isLoading="isFormLoading"
                @handleClick="$router.push(`/new-orders-id/${venue.id}`)" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-text-field class="standart-input-filled standart-input-no-message mt-4 mb-3" v-model="form.name" label="Name"
        :disabled="!isFormEditable" :rules="[rules.required]" color="dark_grey" filled rounded dense></v-text-field>

      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6">RKeeper settings</h3>
        <v-btn icon large color="#5B7FFE" @click="isSettingsOpen.rKeeperSettings = !isSettingsOpen.rKeeperSettings">
          <v-icon>{{ isSettingsOpen.rKeeperSettings ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </div>

      <v-row dense class="mb-4" v-show="isSettingsOpen.rKeeperSettings">
        <v-col cols="12">
          <h5>RKeeper HTTP interface settings</h5>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="form.settings.rKeeperSettings.ip" label="Ip"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('ip') ? [rules.required] : []" type="text" color="dark_grey" filled rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                External IP address of rkeeper HTTP interface
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.login" label="Login"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('login') ? [rules.required] : []" type="text" color="dark_grey" filled rounded
            dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Username for rkeeper HTTP interface
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="form.settings.rKeeperSettings.port" label="Port"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('port') ? [rules.required] : []" type="text" color="dark_grey" filled rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                External port of rkeeper HTTP interface
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.pwd" label="Pwd"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('pwd') ? [rules.required] : []" type="text" color="dark_grey" filled rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Password for rkeeper HTTP interface
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <h5>Basic settings</h5>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="form.settings.rKeeperSettings.stationIdent" label="Station Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('stationIdent') ? [rules.required] : []" type="text" color="dark_grey" filled
            rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Station ID that where orders will be processed.
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.waiterIdent" label="Waiter Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('waiterIdent') ? [rules.required] : []" type="text" color="dark_grey" filled rounded
            dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Waiter ID who will be used as creator of orders (will be used to determine price type)
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.voidIdent" label="Void Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('voidIdent') ? [rules.required] : []" type="text" color="dark_grey" filled rounded
            dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Void Ident
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.orderTypeIdent" label="Order Type Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('orderTypeIdent') ? [rules.required] : []" type="text" color="dark_grey" filled
            rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Order Type ID that will be used for creating orders
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.discountId" label="Discount Id"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('discountId') ? [rules.required] : []" type="text" color="dark_grey" filled rounded
            dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Discount ID that will be used for adding discounts to orders (Must have Changeable Value enabled)
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.printPurposeIdent" label="Print Purpose Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('printPurposeIdent') ? [rules.required] : []" type="text" color="dark_grey" filled
            rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Printer Purpose ID that will be used for printing order info slips
              </v-tooltip>
            </template>
          </v-text-field>
          <v-autocomplete v-model="form.settings.rKeeperSettings.defaultDeliveryMethod" label="Default Delivery Method"
            :disabled="!isFormEditable" class="standart-input-filled standart-input-no-message mb-6"
            :rules="[rules.required]" color="dark_grey" filled rounded dense :items="deliveryMethods"
            :item-text="getItemText" item-value="id" @input="selectLang($event, 'defaultDeliveryMethod')">
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Default delivery method for all items
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="form.settings.rKeeperSettings.tableIdent" label="Table Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('tableIdent') ? [rules.required] : []" type="text" color="dark_grey" filled rounded
            dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Table ID where orders will be created (will be used to determine price type)
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.receiptMaketIdent" label="Receipt Maket Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('receiptMaketIdent') ? [rules.required] : []" type="text" color="dark_grey" filled
            rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Receipt document view ID used that will be used for receipts
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.invoiceMaketId" label="Invoice Maket Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('invoiceMaketId') ? [rules.required] : []" type="text" color="dark_grey" filled
            rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Identifier of invoice view from print scheme
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.taxIdent" label="Tax Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('taxIdent') ? [rules.required] : []" type="text" color="dark_grey" filled rounded
            dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                ID of Tax that will be used for menu generation
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.comboTaxRate" label="Combo Tax Rate"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('comboTaxRate') ? [rules.required] : []" type="text" color="dark_grey" filled
            rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Fixed Tax rate that will be used for combo items
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field v-model="form.settings.rKeeperSettings.pickUpOrderTypeIdent" label="Pick Up Order Type Ident"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField('pickUpOrderTypeIdent') ? [rules.required] : []" type="text" color="dark_grey"
            filled rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Order Type ID that will be used for creating pickup orders (if empty default Order Type ID will be used)
              </v-tooltip>
            </template>
          </v-text-field>
          <v-autocomplete v-model="form.settings.rKeeperSettings.defaultMenuLanguageId" label="Default Menu Language Id"
            :disabled="!isFormEditable" class="standart-input-filled standart-input-no-message mb-6"
            :rules="[rules.required]" color="dark_grey" filled rounded dense :items="languages" :item-text="getItemText"
            item-value="id" @input="selectLang($event, 'defaultMenuLanguageId')">
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Default menu language
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12">
          <h5>Work Parameters</h5>
        </v-col>
        <v-col cols="6">
          <v-checkbox class="standart-checkbox" v-model="form.settings.rKeeperSettings.isPayOrderEnabled"
            :disabled="!isFormEditable">
            <template v-slot:label>
              isPayOrderEnabled
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on"> mdi-help-circle </v-icon>
                </template>
                Enables PayOrder execution, receipt printing (if disabled order will stay in unpaid state)
              </v-tooltip>
            </template>
          </v-checkbox>
          <v-checkbox class="standart-checkbox" v-model="form.settings.rKeeperSettings.isAutoPrintEnabled"
            :disabled="!isFormEditable">
            <template v-slot:label>
              isAutoPrintEnabled
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on"> mdi-help-circle </v-icon>
                </template>
                Enables automatic order info slip printing once order is in production state
              </v-tooltip>
            </template>
          </v-checkbox>
          <v-checkbox class="standart-checkbox" v-model="form.settings.rKeeperSettings.isCreateInvoice"
            :disabled="!isFormEditable">
            <template v-slot:label>
              isCreateInvoice
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on"> mdi-help-circle </v-icon>
                </template>
                Enables/Disables printing of invoice in rkeeper
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox class="standart-checkbox" v-model="form.settings.rKeeperSettings.isUseKds"
            :disabled="!isFormEditable">
            <template v-slot:label>
              isUseKds
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on"> mdi-help-circle </v-icon>
                </template>
                Enables reading of order kdsstate and automatic order status change once marked as ready in KDS
              </v-tooltip>
            </template>
          </v-checkbox>
          <v-checkbox class="standart-checkbox" v-model="form.settings.rKeeperSettings.isDebugMode"
            :disabled="!isFormEditable">
            <template v-slot:label>
              isDebugMode
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on"> mdi-help-circle </v-icon>
                </template>
                ONLY FOR DEBUG - When enabled orders WILL NOT BE CREATED, SAVED IN RKEEPER, only ValidateOrder command
                will be executed.
              </v-tooltip>
            </template>
          </v-checkbox>
          <v-checkbox class="standart-checkbox" v-model="form.settings.rKeeperSettings.isUseComboName"
            :disabled="!isFormEditable">
            <template v-slot:label>
              isUseComboName
              <v-tooltip top class="tooltip">
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on"> mdi-help-circle </v-icon>
                </template>
                Enables usage of combo component names as they are defined in combo scheme instead of using their
                default menu item names
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>

      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6">Venue Self settings</h3>
        <v-btn icon large color="#5B7FFE" @click="isSettingsOpen.venueSelfSettings = !isSettingsOpen.venueSelfSettings">
          <v-icon>{{ isSettingsOpen.venueSelfSettings ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </div>

      <v-row class="mb-4" v-show="isSettingsOpen.venueSelfSettings">
        <v-col class="py-0" cols="6" v-if="!isNew">
          <v-text-field v-model="form.id" label="Venue Self Id"
            class="standart-input-filled standart-input-no-message mb-6" :readonly="!isNew" :rules="[rules.required]"
            type="text" color="dark_grey" :append-icon="isVenueSelfIdCopied ? 'mdi-check' : 'mdi-content-copy'"
            @click:append="copyClipboard('isVenueSelfIdCopied', form.id)" filled rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Venue self ID.
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="py-0" cols="6" v-if="!isNew">
          <v-text-field :value="formatDateTime(dateTimeFormats, form.menuUpdateTimeUtc)" label="Last menu sync time"
            class="standart-input-filled standart-input-no-message mb-6" :readonly="!isNew" :rules="[rules.required]"
            type="text" color="dark_grey" filled rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                Last time when menu was automatically synchronized between rkeeper and delivery platforms.
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="py-0" cols="6" v-for="key of Object.keys(this.form.settings.venueSelfSettings).filter(
          el =>
            ![
              'woltStartDateUtc',
              'boltStartDateUtc',
              'fudyStartDateUtc',
              'woltExpiredDateUtc',
              'boltExpiredDateUtc',
              'fudyExpiredDateUtc',
              'glovoExpiredDateUtc',  
            ].includes(el),
        )" :key="key">
          <v-checkbox class="standart-checkbox" v-if="fieldTypes[key] && fieldTypes[key].type === 'checkbox'"
            v-model="form.settings.venueSelfSettings[key]" :disabled="key === 'useDayLigthSavingTime' ? !form.settings.venueSelfSettings.useCustomTimeZone : !isFormEditable
              " :label="key">
            <template v-if="venueSelfSettingsDesctiptions[key]" v-slot:label>
              {{ key }}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on"> mdi-help-circle </v-icon>
                </template>
                {{ venueSelfSettingsDesctiptions[key] }}
              </v-tooltip>
            </template>
          </v-checkbox>

          <v-row v-else-if="fieldTypes[key] && fieldTypes[key].type === 'select'">
            <v-col v-if="key === 'timeZone'">
              <v-autocomplete v-model="form.settings.venueSelfSettings[key]" label="Time zone"
                class="standart-input-filled standart-input-no-message mb-6" color="dark_grey" :items="timeZones"
                item-text="name" item-value="id" :rules="[rules.required]"
                :disabled="!form.settings.venueSelfSettings.useCustomTimeZone" loader-height="10" filled rounded dense>
              </v-autocomplete>
            </v-col>
            <v-col v-else cols="8">
              <v-autocomplete v-model="form.settings.venueSelfSettings[key]" :label="key"
                :disabled="!isFormEditable || isSelfImageSourceId"
                class="standart-input-filled standart-input-no-message mb-6" color="dark_grey" :items="getIdNames"
                item-text="name" item-value="id" @focus="getIdNamesFocused" :loading="idNamesLoading"
                @input="selectImageSourceId" loader-height="10" filled rounded dense>
                <template v-slot:append-outer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-help-circle </v-icon>
                    </template>
                    {{ venueSelfSettingsDesctiptions[key] }}
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col v-if="key !== 'timeZone'" cols="4">
              <v-checkbox class="standart-checkbox" v-model="isSelfImageSourceId" :disabled="!isFormEditable">
                <template v-slot:label>
                  Select this venue"
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-2" v-on="on"> mdi-help-circle </v-icon>
                    </template>
                    If enabled will use its own venue if for image urls.
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-text-field v-else-if="fieldTypes[key] && fieldTypes[key].type === 'number'"
            v-model="form.settings.venueSelfSettings[key]" :disabled="!isFormEditable" :label="key"
            class="standart-input-filled standart-input-no-message mb-6" type="number" filled rounded dense min="0"
            max="420">
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                {{ venueSelfSettingsDesctiptions[key] }}
              </v-tooltip>
            </template>
          </v-text-field>

          <v-text-field v-else v-model="form.settings.venueSelfSettings[key]" :label="key"
            class="standart-input-filled standart-input-no-message mb-6"
            :disabled="isDisabledForManager(key) || !isFormEditable"
            :rules="isRequiredField(key) ? [rules.required] : []" type="text" color="dark_grey" filled rounded dense>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle </v-icon>
                </template>
                {{ venueSelfSettingsDesctiptions[key] }}
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex">
        <h4 class="form-title d-flex align-center">Wolt instances:</h4>
        <v-btn icon large color="#000000" @click="openAddInstanceModal('woltSettings')">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </div>
      <div v-for="(instance, index) in form.woltSettings" :key="`woltSettings-${instance.id ?? index}`">
        <div class="my-2 d-flex justify-space-between">
          <h4 class="form-title d-flex align-center">
            Wolt settings #{{ index + 1 }} {{ form.woltSettings[index].displayName }}
            {{ form.woltSettings[index].id ? `- ID ${form.woltSettings[index].id}` : '' }}
          </h4>
          <div>
            <v-btn icon large color="#5B7FFE" @click="toggleInstanceView('woltOpen', instance.id)">
              <v-icon>{{ woltOpen.includes(instance.id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row class="mb-8" v-if="woltOpen.includes(instance.id)">
          <v-col class="py-0" cols="6" v-for="key of Object.keys(instance).filter(k => k != 'id' && k != 'isEnabled')"
            :key="key">
            <v-autocomplete v-if="key == 'url'" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :items="woltSettingsUrl"
              v-model="form.woltSettings[index][key]" color="dark_grey" filled rounded dense>
            </v-autocomplete>
            <v-text-field v-else-if="key == 'venueId'" v-model="form.woltSettings[index][key]" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
              :rules="!instance.isEnabled ? [rules.required] : []" type="text" color="dark_grey"
              :append-icon="isWoltVenueIdCopied ? 'mdi-check' : 'mdi-content-copy'"
              @click:append="copyClipboard('isWoltVenueIdCopied', form.woltSettings[index][key])" filled rounded
              dense></v-text-field>
            <v-text-field v-else v-model="form.woltSettings[index][key]" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
              :rules="!instance.isEnabled && isRequiredField(key) ? [rules.required] : []" type="text" color="dark_grey"
              filled rounded dense></v-text-field>
          </v-col>
          <v-col class="py-3 d-flex align-center justify-space-between" cols="12">
            <v-switch class="standart-switch ma-0 ml-5" v-model="form.woltSettings[index].isEnabled" dense hide-details
              :label="form.woltSettings[index].isEnabled ? 'Enabled' : 'Disabled'"></v-switch>
            <Button styleType="primary" text="Delete" class="standart-button-content-width" type="button"
              @handleClick="openDeleteInstanceModal('woltSettings', instance)" />
          </v-col>
        </v-row>
      </div>

      <div class="d-flex">
        <h4 class="form-title d-flex align-center">Fudy instances:</h4>
        <v-btn icon large color="#000000" @click="openAddInstanceModal('fudySettings')">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </div>
      <div v-for="(instance, index) in form.fudySettings" :key="`fudySettings-${instance.id ?? index}`">
        <div class="my-2 d-flex justify-space-between">
          <h3 class="form-title d-flex align-center">
            Fudy settings #{{ index + 1 }} {{ form.fudySettings[index].displayName }}
            {{ form.fudySettings[index].id ? `- ID ${form.fudySettings[index].id}` : '' }}
          </h3>
          <v-btn icon large color="#5B7FFE" @click="toggleInstanceView('fudyOpen', instance.id)">
            <v-icon>{{ fudyOpen.includes(instance.id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </div>
        <v-row class="mb-8" v-if="fudyOpen.includes(instance.id)">
          <v-col class="py-0" cols="6" v-for="key of Object.keys(instance).filter(k => k != 'id' && k != 'isEnabled')"
            :key="key">
            <v-autocomplete v-if="key == 'url'" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :items="fudySettingsUrl"
              v-model="form.fudySettings[index][key]" color="dark_grey" filled rounded dense>
            </v-autocomplete>
            <v-text-field v-else-if="key == 'venueId'" v-model="form.fudySettings[index][key]" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
              :rules="!instance.isEnabled ? [rules.required] : []" type="text" color="dark_grey"
              :append-icon="isFudyVenueIdCopied ? 'mdi-check' : 'mdi-content-copy'"
              @click:append="copyClipboard('isFudyVenueIdCopied', form.fudySettings[index][key])" filled rounded
              dense></v-text-field>
            <v-text-field v-else v-model="form.fudySettings[index][key]" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
              :rules="!instance.isEnabled && isRequiredField(key) ? [rules.required] : []" type="text" color="dark_grey"
              filled rounded dense></v-text-field>
          </v-col>
          <v-col class="py-3 d-flex align-center justify-space-between" cols="12">
            <v-switch class="standart-switch ma-0 ml-5" v-model="form.fudySettings[index].isEnabled" dense hide-details
              :label="form.fudySettings[index].isEnabled ? 'Enabled' : 'Disabled'"></v-switch>
            <Button styleType="primary" text="Delete" class="standart-button-content-width" type="button"
              @handleClick="openDeleteInstanceModal('fudySettings', instance)" />
          </v-col>
        </v-row>
      </div>

      <div class="d-flex">
        <h4 class="form-title d-flex align-center">Bolt instances:</h4>
        <v-btn icon large color="#000000" @click="openAddInstanceModal('boltSettings')">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </div>
      <div v-for="(instance, index) in form.boltSettings" :key="`boltSettings-${instance.id ?? index}`">
        <div class="my-2 d-flex justify-space-between">
          <h3 class="form-title d-flex align-center">
            Bolt settings #{{ index + 1 }} {{ form.boltSettings[index].displayName }}
            {{ form.boltSettings[index].id ? `- ID ${form.boltSettings[index].id}` : '' }}
          </h3>
          <v-btn icon large color="#5B7FFE" @click="toggleInstanceView('boltOpen', instance.id)">
            <v-icon>{{ boltOpen.includes(instance.id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </div>
        <v-row v-if="boltOpen.includes(instance.id)">
          <v-col class="py-0" cols="6" v-for="key of Object.keys(instance).filter(
            k =>
              k != 'id' &&
              k != 'isEnabled' &&
              k != 'tableIds' &&
              k != 'isEnabledTableMapping' &&
              k != 'isPayAndGoSettings' &&
              k != 'paymentType' &&
              k != 'tipsItemId'
          )" :key="key">
            <v-autocomplete v-if="key == 'url'" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :items="boltSettingsUrl"
              v-model="form.boltSettings[index][key]" color="dark_grey" filled rounded dense></v-autocomplete>
            <v-text-field v-else v-model="form.boltSettings[index][key]" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
              :rules="!instance.isEnabled && isRequiredField(key) ? [rules.required] : []" type="text" color="dark_grey"
              filled rounded dense></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-col class="py-0 d-flex" cols="6">
              <h5>Pay and Go settings</h5>
              <v-switch class="standart-switch ma-0 ml-5" v-model="form.boltSettings[index].isPayAndGoSettings" dense
                hide-details :label="form.boltSettings[index].isPayAndGoSettings ? 'Enabled' : 'Disabled'"></v-switch>
            </v-col>
            <div class="pay-and-go" v-if="form.boltSettings[index].isPayAndGoSettings">
              <Button styleType="primary" text="Edit Table Mapping" class="edit-table-mapping-button" type="button"
                @handleClick="openTableMappingModal(index)" />
              <v-col class="py-0 d-flex" cols="6">
                <v-autocomplete :label="'paymentType'" style="height: 100%"
                  class="standart-input-filled standart-input-no-message mb-6" :items="paymentTypes"
                  v-model="form.boltSettings[index].paymentType" color="dark_grey" filled rounded
                  dense></v-autocomplete>
              </v-col>
            </div>
            <div class="pay-and-go" v-if="form.boltSettings[index].isPayAndGoSettings">
              <v-col class="py-0 d-flex" cols="6">
                <v-text-field v-model="form.boltSettings[index].tipsItemId" :label="'tipsItemId'"
                  class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
                  :rules="!instance.isEnabled && isRequiredField('tipsItemId') ? [rules.required] : []" type="text"
                  color="dark_grey" filled rounded dense></v-text-field>
              </v-col>
            </div>
          </v-col>
          <v-dialog v-model="isTableMappingModalOpen" max-width="1200px">
            <v-card>
              <v-card-title class="headline">Table Mapping</v-card-title>
              <div style="overflow-y: auto; max-height: 400px; height: 400px">
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Bolt Table Id</th>
                          <th>RKeeper Table Id</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(table, tableIndex) in form.boltSettings[index].tableIds" :key="tableIndex">
                          <td>
                            <input type="text" v-model="table.boltTableId" class="compact-input"
                              placeholder="Enter Bolt Table Id" />
                          </td>
                          <td>
                            <input type="text" v-model="table.rKeeperTableId" class="compact-input"
                              placeholder="Enter RKeeper Table Id" />
                          </td>
                          <td>
                            <button class="mini-button delete-button" @click="deleteTableMapping(index, tableIndex)">
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="mt-4" style="text-align: right">
                    <button class="mini-button add-button" @click="addTableMapping()">Add Mapping</button>
                  </div>
                </v-card-text>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <button class="mini-button close-button" @click="() => (isTableMappingModalOpen = false)">Close</button>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-col class="py-3 d-flex align-center justify-space-between" cols="12">
            <v-switch class="standart-switch ma-0 ml-5" v-model="form.boltSettings[index].isEnabled" dense hide-details
              :label="form.boltSettings[index].isEnabled ? 'Enabled' : 'Disabled'"></v-switch>
            <Button styleType="primary" text="Delete" class="standart-button-content-width" type="button"
              @handleClick="openDeleteInstanceModal('boltSettings', instance)" />
          </v-col>
        </v-row>
      </div>

      <div class="d-flex">
        <h4 class="form-title d-flex align-center">Glovo instances:</h4>
        <v-btn icon large color="#000000" @click="openAddInstanceModal('glovoSettings')">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </div>
      <div v-for="(instance, index) in form.glovoSettings" :key="`glovoSettings-${instance.id ?? index}`">
        <div class="my-2 d-flex justify-space-between">
          <h3 class="form-title d-flex align-center">
            Glovo settings #{{ index + 1 }} {{ form.glovoSettings[index].displayName }}
            {{ form.glovoSettings[index].id ? `- ID ${form.glovoSettings[index].id}` : '' }}
          </h3>
          <v-btn icon large color="#5B7FFE" @click="toggleInstanceView('glovoOpen', instance.id)">
            <v-icon>{{ glovoOpen.includes(instance.id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </div>
        <v-row v-if="glovoOpen.includes(instance.id)">
          <v-col class="py-0" cols="6" v-for="key of Object.keys(instance).filter(k => k != 'id' && k != 'isEnabled')"
            :key="key">
            <v-autocomplete v-if="key == 'url'" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :items="glovoSettingsUrl"
              v-model="form.glovoSettings[index][key]" color="dark_grey" filled rounded dense></v-autocomplete>
            <v-text-field v-else v-model="form.glovoSettings[index][key]" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
              :rules="!instance.isEnabled && isRequiredField(key) ? [rules.required] : []" type="text" color="dark_grey"
              filled rounded dense></v-text-field>
          </v-col>
          <v-col cols="12" v-for="(table, tableIndex) in form.glovoSettings[index].tableIds" :key="tableIndex">
            <v-row>
              <v-col cols="5">
                <v-text-field v-model="form.glovoSettings[index].tableIds[tableIndex].glovoTableId"
                  label="Glovo Table Id" class="standart-input-filled standart-input-no-message mb-6"
                  :disabled="!isFormEditable"
                  :rules="!instance.isEnabled && isRequiredField('tableIds') ? [rules.required] : []" type="text"
                  color="dark_grey" filled rounded dense></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field v-model="form.glovoSettings[index].tableIds[tableIndex].rKeeperTableId"
                  label="RKeeper Table Id" class="standart-input-filled standart-input-no-message mb-6"
                  :disabled="!isFormEditable"
                  :rules="!instance.isEnabled && isRequiredField('rKeeperTableId') ? [rules.required] : []" type="text"
                  color="dark_grey" filled rounded dense></v-text-field>
              </v-col>
              <v-col cols="2">
                <Button styleType="primary" text="Delete" class="standart-button-content-width" type="button"
                  @handleClick="deleteTableMapping(index, tableIndex)" />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="py-3 d-flex align-center justify-space-between" cols="12">
            <v-switch class="standart-switch ma-0 ml-5" v-model="form.glovoSettings[index].isEnabled" dense hide-details
              :label="form.glovoSettings[index].isEnabled ? 'Enabled' : 'Disabled'"></v-switch>
            <Button styleType="primary" text="Delete" class="standart-button-content-width" type="button"
              @handleClick="openDeleteInstanceModal('glovoSettings', instance)" />
          </v-col>
        </v-row>
      </div>
      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6 d-flex align-center">Licenses</h3>
        <v-btn icon large color="#5B7FFE" @click="isSettingsOpen.licenses = !isSettingsOpen.licenses">
          <v-icon>{{ isSettingsOpen.licenses ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </div>
      <template v-if="isSettingsOpen.licenses">
        <v-row class="mb-8">
          <v-col cols="6"
            v-for="expiredDate in ['boltExpiredDateUtc', 'woltExpiredDateUtc', 'fudyExpiredDateUtc', 'glovoExpiredDateUtc']"
            :key="expiredDate">
            <v-row no-gutters class="d-flex align-bottom">
              <v-col cols="8">
                <v-datetime-picker :disabled="isLicenseEditDisabled" :textFieldProps="dateTimeTextFieldProps"
                  :label="expiredDate" v-model="form.settings.venueSelfSettings[expiredDate]" :dateFormat="dateFormat"
                  :timeFormat="timeFormat" @input="dateTimeChanged(expiredDate)">
                  <template slot="actions" slot-scope="{ parent }">
                    <v-btn color="error lighten-1" @click.native="parent.clearHandler">Clear</v-btn>
                    <v-btn color="success darken-1" @click="parent.okHandler">Ok</v-btn>
                  </template>
                  <template v-slot:dateIcon>
                    <v-icon color="blue darken-2"> mdi-calendar-range </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon color="blue darken-2"> mdi-clock-outline </v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="4">
                <div class="datetime-unlim-checkbox d-flex justify-center align-center">
                  <v-checkbox class="standart-checkbox" @change="unlimCheckboxChange($event, expiredDate)"
                    v-model="expDateUnlimModel[expiredDate]" :disabled="!isFormEditable || isLicenseEditDisabled"
                    label="Unlimited" />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="isRequestLicenseAllowed" cols="12">
            <Button text="Request license" styleType="primary" type="button" class="standart-button-content-width"
              @handleClick="requestLicenseHandler" />
          </v-col>
        </v-row>
      </template>

      <div class="d-flex align-center justify-space-between mt-4">
        <Button text="Cancel" styleType="secondary" type="button" class="standart-button-content-width"
          @handleClick="resetForm" />
        <Button text="Save" styleType="primary" type="submit" class="standart-button-content-width"
          :isLoading="isFormLoading" />
      </div>
    </v-form>
    <VenueModal v-model="isVenueModalShow" :change-status="changeVenueStatus" :availablities="availablities"
      :isLoading="isFormLoading" />
    <MenuModal v-model="isMenuModalShow" :isLoading="isFormLoading" :venueId="venue.id" />
    <VenueScheduleModal v-model="isScheduleModalShow" :change-schedule="changeVenueSchedule"
      :is-schedule-loading="isScheduleLoading" :schedule="schedule.availability" />
    <SelectGroupModal v-model="selectionGroupModalShown" @saveGroupId="changeGroupId" />
    <RequestLicenseModal v-model="reqLicenseModalShown" @sendReqLicForm="sendReqLicHandler" />
    <ChangeVenueGroupModal v-model="isVenueGroupModalShow" @saveForm="saveEdit" :formData="venue"
      :venueGroups="venueGroupsData" />
    <CheckInstancesModal v-model="isCheckInstancesModalShow" :availablities="availablities"
      :shouldPopulateForm="shouldPopulateForm" @checkWolt="checkWolt" :isCheckingFudy="isCheckingFudy"
      :isCheckingBolt="isCheckingBolt" :isCheckingWolt="isCheckingWolt" @checkBolt="checkBolt" @checkFudy="checkFudy" />
    <DeleteInstanceModal v-model="deleteInstanceModalShow" @deleteInstance="deleteInstance"
      :instanceToDelete="instanceToDelete" />
    <AddInstanceModal v-model="addInstanceModalShow" @addInstance="addInstance" :instanceToAdd="instanceToAdd" />
  </div>
</template>

<script>
import dayjs from '@/main.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { TOAST_TYPES, VENUE_STATUSES, VENUE_FIELDS, DEFAULT_SCHEDULE, UNLIMITED_LICENSE_TIME } from '@/data';
import Button from '@/components/commonComponents/Button';
import TextField from '@/components/commonComponents/TextField';
import VenueModal from '@/components/modals/VenueModal';
import MenuModal from '@/components/modals/MenuModal';
import VenueScheduleModal from '@/components/modals/VenueScheduleModal';
import SelectGroupModal from '@/components/modals/SelectGroupModal';
import ChangeVenueGroupModal from '@/components/modals/ChangeVenueGroupModal';
import RequestLicenseModal from '@/components/modals/RequestLicenseModal';
import CheckInstancesModal from '@/components/modals/CheckInstancesModal';
import DeleteInstanceModal from '@/components/modals/DeleteInstanceModal';
import AddInstanceModal from '@/components/modals/AddInstanceModal';
import dateFormat from '@/mixins/date-format';
import { TIME_ZONES } from '@/data';
import { venueSelfSettingsDesctiptions } from '@/data/descriptions';

export default {
  name: 'VenueForm',
  components: {
    VenueModal,
    MenuModal,
    VenueScheduleModal,
    Button,
    SelectGroupModal,
    RequestLicenseModal,
    ChangeVenueGroupModal,
    CheckInstancesModal,
    DeleteInstanceModal,
    AddInstanceModal,
  },
  mixins: [dateFormat],
  data() {
    return {
      selectedInstance: null,
      selectedInstanceIndex: null,
      isTableMappingModalOpen: false,
      venueSelfSettingsDesctiptions: venueSelfSettingsDesctiptions,
      venueGroupsData: [],
      woltSettingsUrl: [
        { text: 'production environment', value: 'https://pos-integration-service.wolt.com' },
        { text: 'test environment', value: 'https://pos-integration-service.development.dev.woltapi.com' },
      ],
      boltSettingsUrl: [
        { text: 'production environment', value: 'https://node.bolt.eu/delivery-provider-pos/genericClient' },
      ],
      fudySettingsUrl: [
        { text: 'test environment', value: 'https://dev-api.fudy.eu' },
        { text: 'production environment', value: 'https://api.fudy.eu' },
      ],
      glovoSettingsUrl: [
        { text: 'test environment', value: 'https://stageapi.glovoapp.com' },
        { text: 'production environment', value: 'https://api.glovoapp.com' },],

      paymentTypes: [
        { text: 'None', value: 'None' },
        { text: 'Pay Order', value: 'PayOrder' },
        { text: 'Auth Pay', value: 'AuthPay' },
      ],
      isFormValid: false,
      fieldTypes: { ...VENUE_FIELDS },
      timeZones: [...TIME_ZONES],
      isSettingsOpen: {
        rKeeperSettings: false,
        venueSelfSettings: false,
        licenses: false,
      },
      woltOpen: [],
      boltOpen: [],
      fudyOpen: [],
      glovoOpen: [],
      schedule: {
        availability: [],
      },
      availablities: {
        woltInstances: [],
        boltInstances: [],
        fudyInstances: [],
        glovoInstances: [],
      },
      form: {
        id: '',
        name: '',
        groupId: this.venueGroupFormId,
        isOnline: null,
        menuUpdateTimeUtc: '',
        settings: {
          rKeeperSettings: {
            ip: '',
            port: '',
            login: '',
            pwd: '',
            stationIdent: '',
            tableIdent: '',
            waiterIdent: '',
            receiptMaketIdent: '',
            invoiceMaketId: '',
            voidIdent: '',
            orderTypeIdent: '',
            fudyDineInOrderTypeIdent: '',
            taxIdent: '',
            pickUpOrderTypeIdent: '',
            printPurposeIdent: '',
            defaultMenuLanguageId: '',
            defaultDeliveryMethod: '',
            comboTaxRate: '',
            discountId: null,
            isCreateInvoice: false,
            isPayOrderEnabled: false,
            isDebugMode: false,
            isAutoPrintEnabled: false,
            isUseComboName: false,
            isUseKds: false,
          },
          venueSelfSettings: {
            companyName: '',
            woltExpiredDateUtc: null,
            boltExpiredDateUtc: null,
            fudyExpiredDateUtc: null,
            glovoExpiredDateUtc: null,
            defaultCookingTimeInMinutes: 1,
            maxCookingTimeInMinutes: 0,
            defaultDelayTimeInMinutes: 0,
            alwaysDefaultDelayTime: false,
            autoAcceptTimeInSeconds: 60,
            imageSourceId: '',
            isPrintOrderEnabled: false,
            isAutoAcceptEnabled: false,
            isAutoPickedUpEnabled: false,
            isAutoSyncMenuEnabled: false,
            isSingleModifierModeEnabled: false,
            isPayOrderAfterAcceptEnabled: false,
            timeZone: null,
            useDayLigthSavingTime: false,
            useCustomTimeZone: false,
            delayOrderSaving: false,
          },
        },
        woltSettings: [],
        boltSettings: [],
        fudySettings: [],
        glovoSettings: [],
      },
      rules: {
        required: value => {
          if (value === 0) {
            return true;
          }
          return !!value || 'Required field';
        },
      },
      isVenueSelfIdCopied: false,
      isWoltVenueIdCopied: false,
      isFudyVenueIdCopied: false,
      isNew: true,
      dateTimeTextFieldProps: {
        class: 'standart-input-filled standart-input-no-message',
        filled: true,
        rounded: true,
        dense: true,
        // clearable: true,
      },
      isVenueModalShow: false,
      isMenuModalShow: false,
      isScheduleModalShow: false,
      isVenueGroupModalShow: false,
      isCheckInstancesModalShow: false,
      isAreaModalShow: false,
      deleteInstanceModalShow: false,
      addInstanceModalShow: false,
      isSyncingMenu: false,
      instanceToAdd: '',
      instanceToDelete: null,
      dateTimeFormats: {
        dateOrder: 0,
        dateSeparator: '.',
        timeFormat: 0,
      },
      expDateUnlimModel: {
        woltExpiredDateUtc: false,
        boltExpiredDateUtc: false,
        fudyExpiredDateUtc: false,
      },
      isSelfImageSourceId: false,
      idNamesLoading: false,
      getIdNamesWasFocused: false,
      selectionGroupModalShown: false,
      reqLicenseModalShown: false,
    };
  },
  async mounted() {
    this.$orderHub2.start(this.venue.id);
    this.$orderHub2.client.on('UpdateMenu', async (data, newMenu, ...args) => {
      if (data === 'Started') {
        this.setLoadingBoolean(true)
        return;
      }

      if (data === 'MenuGetStarted') {
        this.setLoadingBoolean(true)
        return
      }

      if (data === 'MenuGetFinished' && newMenu) {


        localStorage.setItem('menuVersion', newMenu.menuVersion)
        const date = new Date();
        this.setLastUpdateTime(date);
        this.setMenu(newMenu);
        this.setWoltsInCorrectDiscounts(newMenu.woltsInCorrectDiscounts || []);
        localStorage.setItem(this.venue.id, JSON.stringify({ menu: newMenu, lastUpdateTime: date }));
        this.setLoadingBoolean(false)
        return;
      }

      if (data === 'Finished' && newMenu) {
        const removeKeys = (obj, keysToRemove) => {
          return Object.keys(obj)
            .filter(key => !keysToRemove.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };
        const date = new Date();
        this.setLastUpdateTime(date);
        this.setWoltsInCorrectDiscounts(newMenu.woltsInCorrectDiscounts || []);
        const menu = removeKeys(newMenu, ['woltsInCorrectDiscounts', 'lastMenuVersion', 'menuVersion']);

        this.setMenu(menu);
        localStorage.setItem(this.venue.id, JSON.stringify({ menu: menu, lastUpdateTime: date }));
        this.setLoadingBoolean(false)
      }
    });
  },
  created() {
    if (this.getUserData.roleNames[0].toLowerCase() === 'dealer') {
      if (this.$route.params.id && this.$route.params.id === 'new') {
        this.fetchArea(this.getUserData.areaId).then(() => {
          this.form.settings.venueSelfSettings.timeZone = this.getActiveArea.timeZone;
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = this.getActiveArea.useDayLigthSavingTime;
        });
      }
      this.fetchGroupData(`VenueGroups?areaId=${this.getUserData.areaId}`).then(data =>
        this.venueGroupsData.push(...data),
      );
    }
    if (this.getUserData.roleNames[0].toLowerCase() === 'admin') {
      this.getActiveAreaId
        ? this.fetchGroupData(`VenueGroups?areaId=${this.getActiveAreaId}`).then(data =>
          this.venueGroupsData.push(...data),
        )
        : this.fetchGroupData(`VenueGroups`).then(data => this.venueGroupsData.push(...data));
    }
  },
  props: {
    venue: {
      type: Object,
    },
    isFormEditable: {
      type: Boolean,
      default: false,
    },
    isScheduleLoading: {
      type: Boolean,
      default: false,
    },
    venueGroupFormId: {
      type: Number,
      default: 0,
    },
    isFormLoading: {
      type: Boolean,
      default: false,
    },
    clearForm: {
      type: Boolean,
      default: false,
    },
    shouldPopulateForm: {
      type: Boolean,
      default: false,
    },
    isCheckingRk: {
      type: Boolean,
      default: false,
    },
    isCheckingWolt: {
      type: Boolean,
      default: false,
    },
    isCheckingFudy: {
      type: Boolean,
      default: false,
    },
    isCheckingBolt: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'form.name'(newVal) {
      this.$emit('nameUpdate', newVal);
    },
    'form.settings.venueSelfSettings.useCustomTimeZone'(newVal) {
      if (newVal) {
        if (this.$route.params.id && this.$route.params.id === 'new') {
          this.form.settings.venueSelfSettings.timeZone = 0;
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = false;
        } else {
          this.form.settings.venueSelfSettings.timeZone = this.venue.settings.venueSelfSettings.timeZone;
          this.form.settings.venueSelfSettings.useDayLigthSavingTime =
            this.venue.settings.venueSelfSettings.useDayLigthSavingTime;
        }
      } else {
        if (this.$route.params.id && this.$route.params.id === 'new') {
          this.form.settings.venueSelfSettings.timeZone = this.getActiveArea.timeZone;
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = this.getActiveArea.useDayLigthSavingTime;
        } else {
          this.form.settings.venueSelfSettings.timeZone = this.venue.areaTimeZone.timeZone;
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = this.venue.areaTimeZone.useDayLigthSavingTime;
        }
      }
    },
    clearForm(newVal) {
      if (newVal) {
        this.resetForm();
      }
    },
    venue: {
      immediate: true,
      deep: true,
      handler() {
        if (this.shouldPopulateForm === true && Object.prototype.hasOwnProperty.call(this.venue, 'settings')) {
          this.populateForm();
          this.isNew = false;
        }
      },
    },
    shouldPopulateForm(newVal) {
      if (newVal) {
        this.populateForm();
        this.isNew = false;
      }
    },
    isSelfImageSourceId(e) {
      if (e === true) this.form.settings.venueSelfSettings.imageSourceId = null;
    },
  },
  computed: {
    ...mapGetters({
      getRoles: 'app/getRoles',
      getLanguages: 'venues/getLanguages',
      getDeliveryMethods: 'venues/getDeliveryMethods',
      getActiveVenueGroupId: 'venueGroups/getActiveVenueGroupId',
      getActiveAreaId: 'areas/getActiveAreaId',
      getActiveArea: 'areas/getItem',
      getGeneralSettings: 'app/getGeneralSettings',
      getIdNames: 'venues/getIdNames',
      getMenu: 'venues/getMenu',
      getUserData: 'app/getUserData',
    }),
    languages() {
      return this.getLanguages;
    },
    deliveryMethods() {
      return this.getDeliveryMethods;
    },
    venueStatus() {
      if (!Object.prototype.hasOwnProperty.call(this.venue, 'isOnline')) return { color: 'white' };
      else return VENUE_STATUSES.find(el => el.value === this.venue.isOnline);
    },
    timeFormat() {
      let timeFormat = this.dateTimeFormats.timeFormat === 1 ? 'hh:mm' : 'HH:mm';
      return timeFormat;
    },
    dateFormat() {
      let dateSeparator = this.dateTimeFormats.dateSeparator;
      let dateFormat =
        this.dateTimeFormats.dateOrder === 1
          ? `dd${dateSeparator}MM${dateSeparator}yyyy`
          : `MM${dateSeparator}dd${dateSeparator}yyyy`;
      return dateFormat;
    },
    isLicenseEditDisabled() {
      return this.getRoles.includes('Manager') || this.getRoles.includes('Dealer');
    },
    isRequestLicenseAllowed() {
      return this.getRoles[0].toLowerCase() === 'dealer';
    },
  },
  methods: {
    ...mapActions({
      fetchIdNames: 'venues/fetchIdNames',
      fetchMenu: 'venues/fetchMenu',
      fetchArea: 'areas/fetchItem',
      editVenueGroup: 'venues/changeItemToDB',
      addLicReq: 'licRequests/addItemToDB',
      fetchGroupData: 'venueGroups/fetchData',
      fetchActualVenues: 'venues/fetchActualVenues',
    }),
    ...mapMutations({
      setMenu: 'venues/setMenu',
      setLastUpdateTime: 'venues/setLastUpdateTime',
      setWoltsInCorrectDiscounts: 'venues/setWoltsInCorrectDiscounts',
      setLoadingBoolean: 'venues/setLoadingBoolean',
    }),
    openTableMappingModal(index) {
      this.selectedInstance = this.form.boltSettings[index];
      this.selectedInstanceIndex = index;
      this.isTableMappingModalOpen = true;
    },
    addTableMapping() {
      this.selectedInstance.tableIds.push({ boltTableId: '', rKeeperTableId: '' });
    },
    deleteTableMapping(instanceIndex, tableIndex) {
      this.form.boltSettings[instanceIndex].tableIds.splice(tableIndex, 1);
    },

    closeTableMappingModal() {
      this.tableMappingModalOpen = false;
      this.currentTableMapping = [];
      this.currentInstanceIndex = null;
    },
    addBoltTableMapping(instanceIndex) {

      this.form.boltSettings[instanceIndex].tableIds.push({
        boltTableId: '',
        rKeeperTableId: '',
      });
    },
    openAddInstanceModal(aggregator) {
      this.instanceToAdd = aggregator;
      this.addInstanceModalShow = true;
    },
    openDeleteInstanceModal(aggregator, instance) {
      this.instanceToDelete = {
        aggregator,
        id: instance.id,
        name: instance.displayName,
      };
      this.deleteInstanceModalShow = true;
    },
    toggleInstanceView(field, id) {
      if (this[field].includes(id)) {
        this[field] = this[field].filter(el => el !== id);
      } else {
        this[field].push(id);
      }
    },
    changeRKeeperSettingsField(key, value) {
      this.form.settings.rKeeperSettings[key] = value;
    },
    addInstance(aggregator) {
      const newInstances = {
        woltSettings: {
          isEnabled: true,
          displayName: '',
          url: '',
          login: '',
          pwd: '',
          venueId: '',
          apiKey: '',
          id: null,
          currencyIdent: '',
          rootSelectorIdent: '',
          orderTypeIdent: '',
          pickUpOrderTypeIdent: '',
          orderCategory: '',
          tableId: '',
        },
        boltSettings: {
          isEnabled: true,
          displayName: '',
          url: '',
          providerId: '',
          id: null,
          currencyIdent: '',
          rootSelectorIdent: '',
          orderTypeIdent: '',
          pickUpOrderTypeIdent: '',
          orderCategory: '',
          tableId: '',
          tableIds: [],
          paymentType: 'None',
          tipsItemId: '',
          isPayAndGoSettings: false,
        },
        fudySettings: {
          isEnabled: true,
          displayName: '',
          url: '',
          venueId: '',
          apiKey: '',
          id: null,
          currencyIdent: '',
          rootSelectorIdent: '',
          orderTypeIdent: '',
          pickUpOrderTypeIdent: '',
          orderCategory: '',
          tableId: '',
        },
        glovoSettings: {
          isEnabled: true,
          displayName: '',
          url: '',
          venueId: '',
          apiKey: '',
          id: null,
          currencyIdent: '',
          rootSelectorIdent: '',
          orderTypeIdent: '',
          pickUpOrderTypeIdent: '',
          orderCategory: '',
          tableId: '',
          storeId: '',
        },
      };
      this.form[aggregator] = [...this.form[aggregator], newInstances[aggregator]];
      this.instanceToAdd = '';
    },
    deleteInstance(aggregator, instanceId) {
      this.form[aggregator] = this.form[aggregator]?.filter(el => el.id !== instanceId);
      this.instanceToDelete = null;
    },
    isDisabledForManager(field) {
      let res = false;
      switch (field) {
        case 'companyName':
          res = true;
          break;
      }
      return res && this.getRoles.includes('Manager');
    },
    isRequiredField(field) {
      let res = true;
      switch (field) {
        case 'discountId':
          res = false;
          break;
        case 'pickUpOrderTypeIdent':
          res = false;
          break;
        case 'fudyDineInOrderTypeIdent':
          res = false;
          break;
        case 'companyName':
          res = false;
          break;
        case 'invoiceMaketId':
          res = false;
          break;
        case 'isCreateInvoice':
          res = false;
          break;
        case 'currencyIdent':
          res = false;
          break;
        case 'rootSelectorIdent':
          res = false;
          break;
        case 'orderTypeIdent':
          res = false;
          break;
        case 'orderCategory':
          res = false;
          break;
        case 'tableId':
          res = false;
          break;
      }
      return res;
    },
    changeGroupId(id) {
      this.form.groupId = id;
    },
    validateForm(ref) {
      this.isFormValid = this.$refs[ref].validate();
      if (!this.form.settings.venueSelfSettings.imageSourceId && !this.isSelfImageSourceId) {
        this.isFormValid = false;
        this.$toast.open({ message: 'Please select image source id or select this venue!', type: TOAST_TYPES.ERROR });
      }

      if (this.form.settings.venueSelfSettings.autoAcceptTimeInSeconds > 420) {
        this.$toast.open({ message: 'Auto accept time must be under 7 minutes', type: TOAST_TYPES.ERROR });
        return;
      }

      if (!this.form.groupId) {
        if (!this.venue.groupId && !this.getActiveVenueGroupId && !this.venueGroupFormId)
          this.selectionGroupModalShown = true;
        else
          this.form.groupId = this.venue.groupId
            ? this.venue.groupId
            : this.getActiveVenueGroupId
              ? this.getActiveVenueGroupId
              : this.venueGroupFormId;
      }
      if (this.isFormValid) {
        let formToSend = JSON.parse(JSON.stringify(this.form));
        let defCookingTime = Number(formToSend.settings.venueSelfSettings.defaultCookingTimeInMinutes);
        let autoAcceptTime = Number(formToSend.settings.venueSelfSettings.autoAcceptTimeInSeconds);
        let maxCookingTime = Number(formToSend.settings.venueSelfSettings.maxCookingTimeInMinutes);
        let defaultDelayTime = Number(formToSend.settings.venueSelfSettings.defaultDelayTimeInMinutes);
        formToSend.settings.venueSelfSettings.defaultCookingTimeInMinutes = defCookingTime;
        formToSend.settings.venueSelfSettings.autoAcceptTimeInSeconds = autoAcceptTime;
        formToSend.settings.venueSelfSettings.maxCookingTimeInMinutes = maxCookingTime;
        formToSend.settings.venueSelfSettings.defaultDelayTimeInMinutes = defaultDelayTime;
        delete formToSend.status;
        delete formToSend.isOnline;

        if (!this.form.settings.venueSelfSettings.useCustomTimeZone) {
          formToSend.settings.venueSelfSettings.timeZone = null;
          formToSend.settings.venueSelfSettings.useDayLigthSavingTime = this.venue.areaTimeZone.isDayLigthSaving;
        }
        console.log(formToSend);

        this.$emit('submitForm', 'venue', formToSend);
        this.checkAndUpdateLicense();
      } else {
        this.isSettingsOpen = {
          rKeeperSettings: true,
          venueSelfSettings: true,
          licenses: true,
        };
        this.$toast.open({ message: 'Please fill in required fields!', type: TOAST_TYPES.ERROR });
      }
    },
    resetForm() {
      this.$emit('resetForm');
    },
    selectLang(e, key) {
      if (key === 'defaultMenuLanguageId') {
        this.form.settings.rKeeperSettings.defaultMenuLanguageId = e;
      }
    },
    populateForm() {
      if (this.venue.settings) {
        this.checkAndUpdateLicense();
      }

      if (this.venue && Object.prototype.hasOwnProperty.call(this.venue, 'settings')) {
        this.schedule =
          this.venue.info?.schedule && this.venue.info.schedule.availability.length > 0
            ? { ...this.venue.info.schedule }
            : { availability: [...DEFAULT_SCHEDULE] };
        (this.availablities = { ...this.venue.availablities }),
          (this.form = {
            id: this.venue.id,
            name: this.venue.name,
            isOnline: this.venue.isOnline,
            menuUpdateTimeUtc: this.venue.menuUpdateTimeUtc,
            isWoltLicensed: this.venue.isWoltLicensed,
            isBoltLicensed: this.venue.isBoltLicensed,
            settings: {
              rKeeperSettings: {
                ip: this.venue.settings.rKeeperSettings.ip,
                port: this.venue.settings.rKeeperSettings.port,
                login: this.venue.settings.rKeeperSettings.login,
                pwd: this.venue.settings.rKeeperSettings.pwd,
                stationIdent: this.venue.settings.rKeeperSettings.stationIdent,
                tableIdent: this.venue.settings.rKeeperSettings.tableIdent,
                waiterIdent: this.venue.settings.rKeeperSettings.waiterIdent,
                receiptMaketIdent: this.venue.settings.rKeeperSettings.receiptMaketIdent,
                invoiceMaketId: this.venue.settings.rKeeperSettings.invoiceMaketId,
                voidIdent: this.venue.settings.rKeeperSettings.voidIdent,
                orderTypeIdent: this.venue.settings.rKeeperSettings.orderTypeIdent,
                taxIdent: this.venue.settings.rKeeperSettings.taxIdent,
                pickUpOrderTypeIdent: this.venue.settings.rKeeperSettings.pickUpOrderTypeIdent,
                comboTaxRate: this.venue.settings.rKeeperSettings.comboTaxRate,
                printPurposeIdent: this.venue.settings.rKeeperSettings.printPurposeIdent,
                defaultMenuLanguageId: this.venue.settings.rKeeperSettings.defaultMenuLanguageId,
                defaultDeliveryMethod: this.venue.settings.rKeeperSettings.defaultDeliveryMethod,
                discountId: this.venue.settings.rKeeperSettings.discountId,
                isCreateInvoice: this.venue.settings.rKeeperSettings.isCreateInvoice,
                isPayOrderEnabled: this.venue.settings.rKeeperSettings.isPayOrderEnabled,
                isDebugMode: this.venue.settings.rKeeperSettings.isDebugMode,
                isAutoPrintEnabled: this.venue.settings.rKeeperSettings.isAutoPrintEnabled,
                isUseComboName: this.venue.settings.rKeeperSettings.isUseComboName,
                isUseKds: this.venue.settings.rKeeperSettings.isUseKds,
              },
              venueSelfSettings: {
                companyName: this.venue.settings.venueSelfSettings.companyName,
                woltExpiredDateUtc: this.venue.settings.venueSelfSettings.woltExpiredDateUtc
                  ? new Date(this.venue.settings.venueSelfSettings.woltExpiredDateUtc)
                  : null,
                boltExpiredDateUtc: this.venue.settings.venueSelfSettings.boltExpiredDateUtc
                  ? new Date(this.venue.settings.venueSelfSettings.boltExpiredDateUtc)
                  : null,
                fudyExpiredDateUtc: this.venue.settings.venueSelfSettings.fudyExpiredDateUtc
                  ? new Date(this.venue.settings.venueSelfSettings.fudyExpiredDateUtc)
                  : null,
                glovoExpiredDateUtc: this.venue.settings.venueSelfSettings.glovoExpiredDateUtc
                  ? new Date(this.venue.settings.venueSelfSettings.glovoExpiredDateUtc)
                  : null,
                defaultCookingTimeInMinutes: this.venue.settings.venueSelfSettings.defaultCookingTimeInMinutes,
                maxCookingTimeInMinutes: this.venue.settings.venueSelfSettings.maxCookingTimeInMinutes,
                defaultDelayTimeInMinutes: this.venue.settings.venueSelfSettings.defaultDelayTimeInMinutes,
                autoAcceptTimeInSeconds: this.venue.settings.venueSelfSettings.autoAcceptTimeInSeconds,
                alwaysDefaultDelayTime: this.venue.settings.venueSelfSettings.alwaysDefaultDelayTime,
                imageSourceId: this.venue.settings.venueSelfSettings.imageSourceId,
                isPrintOrderEnabled: this.venue.settings.venueSelfSettings.isPrintOrderEnabled,
                isAutoAcceptEnabled: this.venue.settings.venueSelfSettings.isAutoAcceptEnabled,
                isAutoPickedUpEnabled: this.venue.settings.venueSelfSettings.isAutoPickedUpEnabled,
                isAutoSyncMenuEnabled: this.venue.settings.venueSelfSettings.isAutoSyncMenuEnabled,
                isSingleModifierModeEnabled: this.venue.settings.venueSelfSettings.isSingleModifierModeEnabled,
                isPayOrderAfterAcceptEnabled: this.venue.settings.venueSelfSettings.isPayOrderAfterAcceptEnabled,
                delayOrderSaving: this.venue.settings.venueSelfSettings.delayOrderSaving,
                useCustomTimeZone: this.venue.settings.venueSelfSettings.useCustomTimeZone,
                timeZone: this.venue.settings.venueSelfSettings.useCustomTimeZone
                  ? this.venue.settings.venueSelfSettings.timeZone
                  : this.venue.areaTimeZone.timeZone,
                useDayLigthSavingTime: this.venue.settings.venueSelfSettings.useCustomTimeZone
                  ? this.venue.settings.venueSelfSettings.useDayLigthSavingTime
                  : this.venue.areaTimeZone.useDayLigthSavingTime,
              },
            },
            woltSettings: this.venue.woltSettings
              ? this.venue.woltSettings.map(instance => ({
                isEnabled: instance.isEnabled ?? false,
                displayName: instance.displayName ?? '',
                url: instance.url ?? '',
                login: instance.login ?? '',
                pwd: instance.pwd ?? '',
                venueId: instance.venueId ?? '',
                apiKey: instance.apiKey ?? '',
                id: instance.id ?? null,
                currencyIdent: instance.currencyIdent ?? '',
                rootSelectorIdent: instance.rootSelectorIdent ?? '',
                orderTypeIdent: instance.orderTypeIdent ?? '',
                pickUpOrderTypeIdent: instance.pickUpOrderTypeIdent ?? '',
                orderCategory: instance.orderCategory ?? '',
                tableId: instance.tableId ?? '',
              }))
              : [],
            fudySettings: this.venue.fudySettings
              ? this.venue.fudySettings.map(instance => ({
                isEnabled: instance.isEnabled ?? false,
                displayName: instance.displayName ?? '',
                url: instance.url ?? '',
                venueId: instance.venueId ?? '',
                apiKey: instance.apiKey ?? '',
                id: instance.id ?? null,
                currencyIdent: instance.currencyIdent ?? '',
                rootSelectorIdent: instance.rootSelectorIdent ?? '',
                orderTypeIdent: instance.orderTypeIdent ?? '',
                pickUpOrderTypeIdent: instance.pickUpOrderTypeIdent ?? '',
                orderCategory: instance.orderCategory ?? '',
                tableId: instance.tableId ?? '',
              }))
              : [],
            boltSettings: this.venue.boltSettings
              ? this.venue.boltSettings.map(instance => ({
                isEnabled: instance.isEnabled ?? false,
                displayName: instance.displayName ?? '',
                url: instance.url ?? '',
                providerId: instance.providerId ?? '',
                id: instance.id ?? null,
                currencyIdent: instance.currencyIdent ?? '',
                rootSelectorIdent: instance.rootSelectorIdent ?? '',
                orderTypeIdent: instance.orderTypeIdent ?? '',
                pickUpOrderTypeIdent: instance.pickUpOrderTypeIdent ?? '',
                orderCategory: instance.orderCategory ?? '',
                tableId: instance.tableId ?? '',
                tableIds: instance.tableIds ?? [],
                paymentType: instance.paymentType ?? 'None',
                tipsItemId: instance.tipsItemId ?? '',
                isPayAndGoSettings: instance.isPayAndGoSettings ?? false,
              }))
              : [],
            glovoSettings: this.venue.glovoSettings
              ? this.venue.glovoSettings.map(instance => ({
                isEnabled: instance.isEnabled ?? false,
                displayName: instance.displayName ?? '',
                url: instance.url ?? '',
                storeId: instance.storeId ?? '',
                id: instance.id ?? null,
                currencyIdent: instance.currencyIdent ?? '',
                rootSelectorIdent: instance.rootSelectorIdent ?? '',
                orderTypeIdent: instance.orderTypeIdent ?? '',
                pickUpOrderTypeIdent: instance.pickUpOrderTypeIdent ?? '',
                orderCategory: instance.orderCategory ?? '',
                tableId: instance.tableId ?? '',
              }))
              : [],
          });

        if (!this.venue.settings.venueSelfSettings.imageSourceId) this.isSelfImageSourceId = true;

        if (
          Object.prototype.hasOwnProperty.call(this.venue.settings.venueSelfSettings, 'boltExpiredDateUtc') &&
          dayjs(this.venue.settings.venueSelfSettings.boltExpiredDateUtc).isSame(dayjs(UNLIMITED_LICENSE_TIME))
        ) {
          this.expDateUnlimModel.boltExpiredDateUtc = true;
        } else {
          this.expDateUnlimModel.boltExpiredDateUtc = false;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.venue.settings.venueSelfSettings, 'woltExpiredDateUtc') &&
          dayjs(this.venue.settings.venueSelfSettings.woltExpiredDateUtc).isSame(dayjs(UNLIMITED_LICENSE_TIME))
        ) {
          this.expDateUnlimModel.woltExpiredDateUtc = true;
        } else {
          this.expDateUnlimModel.woltExpiredDateUtc = false;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.venue.settings.venueSelfSettings, 'fudyExpiredDateUtc') &&
          dayjs(this.venue.settings.venueSelfSettings.fudyExpiredDateUtc).isSame(dayjs(UNLIMITED_LICENSE_TIME))
        ) {
          this.expDateUnlimModel.fudyExpiredDateUtc = true;
        } else {
          this.expDateUnlimModel.fudyExpiredDateUtc = false;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.venue.settings.venueSelfSettings, 'glovoExpiredDateUtc') &&
          dayjs(this.venue.settings.venueSelfSettings.glovoExpiredDateUtc).isSame(dayjs(UNLIMITED_LICENSE_TIME))
        ) {
          this.expDateUnlimModel.glovoExpiredDateUtc = true;
        } else {
          this.expDateUnlimModel.glovoExpiredDateUtc = false;
        }
      }

      let venueGroupId;
      if (this.venue.groupId) {
        venueGroupId = this.venue.groupId;
      } else if (this.venueGroupFormId) {
        venueGroupId = this.venueGroupFormId;
      } else {
        venueGroupId = '';
      }
      if (this.getUserData.areaId) {
        this.fetchIdNames(`/venues/id-name?areaId=${this.getUserData.areaId}`).then(() => {
          this.idNamesLoading = false;
        });
      } else if (this.getActiveAreaId) {
        this.fetchIdNames(`/venues/id-name?areaId=${this.getActiveAreaId}`).then(() => {
          this.idNamesLoading = false;
        });
      } else {
        this.fetchIdNames(`/venues/id-name?groupId=${venueGroupId}`).then(() => {
          this.idNamesLoading = false;
        });
      }

      if (this.getRoles[0].toLowerCase() === 'manager' || this.$route.params.id) {
        this.venueId = this.$route.params.id;
      }
    },
    saveEdit(form) {
      this.editVenueGroup(form).then(() => {
        this.isVenueGroupModalShow = false;
        this.$toast.open({ message: 'Restaurant subgroup has been successfully edited!', type: TOAST_TYPES.SUCCESS });
      });
    },
    checkRk() {
      this.$emit('checkConnection', 'rk');
    },
    checkWolt(instanceId) {
      this.$emit('checkConnection', 'wolt', { instanceId });
    },
    checkFudy(instanceId) {
      this.$emit('checkConnection', 'fudy', { instanceId });
    },
    checkBolt(instanceId) {
      this.$emit('checkConnection', 'bolt', { instanceId });
    },
    checkGlovo(instanceId) {
      this.$emit('checkConnection', 'glovo', { instanceId });
    },
    changeVenueStatus(disId, isOnline, instanceId) {
      let dataToSend = {
        id: this.venue.id,
        distributorId: disId,
        instanceId: instanceId,
        isOnline: isOnline,
      };
      this.$emit('changeCurrentVenueStatus', dataToSend);
    },
    changeVenueSchedule(schedule) {
      this.$emit('changeSchedule', schedule);
    },
    openVenueModal() {
      this.isVenueModalShow = true;
    },
    closeVenueModal() {
      this.isVenueModalShow = false;
    },
    getNewUpdatedMenu(actualMenu) {
      const obj = {
        venueId: this.venueId,
        menu: {},
      };

      Object.keys(actualMenu).forEach(provider => {
        const providerInstances = actualMenu[provider];

        // Пропускаем не нужные ключи
        if (['woltsInCorrectDiscounts', 'venueId', 'menuVersion'].includes(provider)) {
          return;
        }

        if (providerInstances && providerInstances.length > 0) {
          obj.menu[provider] = this.filteredProviders?.[provider]?.map(menu => {
            // Ищем соответствующий instance по menu.id
            const matchingInstance = providerInstances.find(instance => instance.id === menu.id);

            // Рекурсивная функция для обработки дерева
            const updateChildren = children => {
              return children.map(child => {
                // Проверяем совпадение id
                const isDisabled = matchingInstance ? matchingInstance.identifiers.includes(child.id) : false;

                // Рекурсивно обрабатываем вложенные элементы
                return {
                  ...child,
                  isEnabled: !isDisabled, // Выключаем элемент, если он совпадает
                  children: child.children ? updateChildren(child.children) : [], // Обрабатываем детей
                };
              });
            };

            return {
              id: menu.id,
              name: menu.name,
              children: updateChildren(menu.children), // Обрабатываем children через рекурсию
            };
          });
        } else {
          obj.menu[provider] = this.filteredProviders?.[provider]?.length ? this.filteredProviders?.[provider] : [];
        }
      });

      return obj;
    },
    async openMenuModal() {
      if (
        localStorage.getItem(this.venue.id) &&
        JSON.stringify(JSON.parse(localStorage.getItem(this.venue.id)).menu) !== '{}'
      ) {
        const menuFromLocalStorage = JSON.parse(localStorage.getItem(this.venue.id));
        this.setMenu(menuFromLocalStorage.menu);
        this.setLastUpdateTime(menuFromLocalStorage.lastUpdateTime);
        this.setMenu(menuFromLocalStorage.menu);
        this.setLastUpdateTime(menuFromLocalStorage.lastUpdateTime);
        this.isMenuModalShow = true;
      } else {
        const removePrimaryKeys = (obj, keysToRemove) => {
          return Object.keys(obj)
            .filter(key => !keysToRemove.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };

        const menu = await this.fetchMenu(this.venue.id);
        const keysToRemove = ['woltsInCorrectDiscounts', 'lastMenuVersion', 'menuVersion'];
        const filteredObj = removePrimaryKeys(menu, keysToRemove);
        const date = new Date();
        this.setLastUpdateTime(date);
        this.setWoltsInCorrectDiscounts(menu.woltsInCorrectDiscounts || []);
        localStorage.setItem(this.venue.id, JSON.stringify({ menu: filteredObj, lastUpdateTime: date }));
        this.isMenuModalShow = true;
      }
    },
    closeMenuModal() {
      this.isMenuModalShow = false;
    },
    openInstanceCheckModal() {
      this.isCheckInstancesModalShow = true;
    },
    openVenueScheduleModal() {
      this.isScheduleModalShow = true;
    },
    closeVenueScheduleModal() {
      this.isScheduleModalShow = false;
    },
    openVenueGroupModal() {
      this.isVenueGroupModalShow = true;
    },
    openAreaModal() {
      this.isAreaModalShow = true;
    },
    copyClipboard(iconState, value) {
      navigator.clipboard.writeText(value);
      this[iconState] = true;
      setTimeout(() => {
        this[iconState] = false;
      }, 1000);
    },
    getItemText(item) {
      return item.rKeeperName ? `${item.name} - ${item.rKeeperName}` : item.name;
    },
    unlimCheckboxChange(val, key) {
      if (val) {
        this.form.settings.venueSelfSettings[key] = new Date(UNLIMITED_LICENSE_TIME);
      } else {
        this.form.settings.venueSelfSettings[key] = null;
      }
    },
    dateTimeChanged(key) {
      this.expDateUnlimModel[key] = false;
    },
    getIdNamesFocused() {
      if (!this.getIdNamesWasFocused && this.isNew) {
        this.getIdNamesFocused = true;
        this.idNamesLoading = true;
        let venueGroupId;
        if (this.venue.groupId) {
          venueGroupId = this.venue.groupId;
        } else if (this.venueGroupFormId) {
          venueGroupId = this.venueGroupFormId;
        } else {
          venueGroupId = '';
        }
        if (this.getUserData.areaId) {
          this.fetchIdNames(`/venues/id-name?areaId=${this.getUserData.areaId}`)
            .then(() => {
              this.idNamesLoading = false;
            })
            .catch(() => {
              this.idNamesLoading = false;
            });
        } else if (this.getActiveAreaId) {
          this.fetchIdNames(`/venues/id-name?areaId=${this.getActiveAreaId}`)
            .then(() => {
              this.idNamesLoading = false;
            })
            .catch(() => {
              this.idNamesLoading = false;
            });
        } else {
          this.fetchIdNames(`/venues/id-name?groupId=${venueGroupId}`)
            .then(() => {
              this.idNamesLoading = false;
            })
            .catch(() => {
              this.idNamesLoading = false;
            });
        }
      }
    },
    selectImageSourceId(e) {
      this.form.settings.venueSelfSettings.imageSourceId = e;
    },
    checkAndUpdateLicense() {
      const services = [
        {
          name: 'Wolt',
          isActive: this.venue.woltSettings?.some(i => i.isEnabled),
          isLicensed: this.venue.isWoltLicensed,
          hasLicenseDate: Object.prototype.hasOwnProperty.call(
            this.venue.settings?.venueSelfSettings,
            'woltExpiredDateUtc',
          ),
        },
        {
          name: 'Bolt',
          isActive: this.venue.boltSettings?.some(i => i.isEnabled),
          isLicensed: this.venue.isBoltLicensed,
          hasLicenseDate: Object.prototype.hasOwnProperty.call(
            this.venue.settings?.venueSelfSettings,
            'boltExpiredDateUtc',
          ),
        },
        {
          name: 'Fudy',
          isActive: this.venue.fudySettings?.some(i => i.isEnabled),
          isLicensed: this.venue.isFudyLicensed,
          hasLicenseDate: Object.prototype.hasOwnProperty.call(
            this.venue.settings?.venueSelfSettings,
            'fudyExpiredDateUtc',
          ),
        },
        {
          name: 'Glovo',
          isActive: this.venue.glovoSettings?.some(i => i.isEnabled),
          isLicensed: this.venue.isGlovoLicensed,
          hasLicenseDate: Object.prototype.hasOwnProperty.call(
            this.venue.settings?.venueSelfSettings,
            'glovoExpiredDateUtc',
          ),
        },
      ];

      const expiredServices = services
        .filter(service => service.isActive && !service.isLicensed)
        .map(service => service.name);

      if (expiredServices.length > 0) {
        this.$toast.open({
          message: `${expiredServices.join(', ')} license${expiredServices.length > 1 ? 's are' : ' is'} expired!`,
          type: TOAST_TYPES.ERROR,
          duration: 5000,
        });

      }

      const notAssignedServices = services
        .filter(service => service.isActive && !service.hasLicenseDate)
        .map(service => service.name);

      if (notAssignedServices.length > 0) {
        this.$toast.open({
          message: `${notAssignedServices.join(', ')} license${notAssignedServices.length > 1 ? 's are' : ' is'
            } not assigned!`,
          type: TOAST_TYPES.ERROR,
          duration: 5000,
        });
      }
    },
    requestLicenseHandler() {
      this.reqLicenseModalShown = true;
    },
    async sendReqLicHandler(comment) {
      this.reqLicenseModalShown = false;
      let form = {
        venueId: this.venue.id,
        comment: comment,
      };
      try {
        let data = await this.addLicReq(form);
        if (data.status <= 300) {
          this.$toast.open({ message: 'License request has successfully been sent!', type: TOAST_TYPES.SUCCESS });
        }
      } catch (err) {
        this.$toast.type({ message: 'Unexpected error!', type: TOAST_TYPES.ERROR });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  max-width: 300px;
}

.datetime-unlim-checkbox {
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 1.125rem !important;
  background: #f0f0f0;
  height: 58px;
  box-sizing: border-box;
}

/* Стили для компактных текстовых полей */
.compact-input {
  width: 100%;
  font-size: 12px;
  /* Уменьшенный шрифт */
  padding: 4px;
  /* Компактные отступы */
  border: 1px solid #ddd;
  /* Лёгкая рамка */
  border-radius: 4px;
  /* Скруглённые углы */
  background-color: #f9f9f9;
  /* Светлый фон */
  outline: none;
  height: auto;
}

.compact-input:focus {
  border-color: #3f51b5;
  /* Цвет рамки при фокусе */
  background-color: #ffffff;
  /* Белый фон при редактировании */
  box-shadow: 0 0 4px #3f51b5;
}

/* Миниатюрные кнопки */
.mini-button {
  font-size: 10px;
  /* Ещё меньше шрифт */
  padding: 2px 6px;
  /* Уменьшенные отступы */
  border-radius: 4px;
  /* Скруглённые углы */
  height: auto;
  /* Автоматическая высота */
  min-width: 60px;
  /* Минимальная ширина */
  cursor: pointer;
}

.pay-and-go {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.edit-table-mapping-button {
  padding: 0 !important;
  height: 60px;
  margin-left: 12px;
}
</style>
